import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  CameraAltOutlined,
  CheckCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  SaveAsTwoTone,
} from "@mui/icons-material";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/style.css";
import moment from "moment-timezone";
import clm from "country-locale-map";
import toast from "react-hot-toast";
import countries from "iso-3166-1-alpha-2"; // Importing the countries object from the iso-3166-1-alpha-2 library
import GstinValidator from "@hadeeb/gstin-validator";
import ApiService from "../../../apis/ApiService";
import {
  isEmpty,
  isValidEmail,
  orderErrorFocus,
} from "../../config/fieldConfig";
import { ORG_ID } from "../../config/sessionStorage";
import { VERIFY_ADMIN_EMAIL, VERIFY_EMAIL } from "../../config/toastMessage";
import { INDIAN_CURRENCY } from "../../config/constants";
import { CompanySetupService } from "./service";
import FullScreenLoader from "../../common/LoadingScreen";
import { validatePhoneNumber } from "../../../utils/Validation/validatePhoneNumber";

export default function CompanySetup() {
  const {
    onCompanySetup,
    registeredData,
    handleFileChangeLogo,
    fields,
    selectedImage,
    setSelectedImage,
    setSelectedFileLogo,
    handleAvatarClick,
    fileInputRef,
    verifyOtp,
    setVerifyOtp,
    isVerify,
    setIsVerify,
    generateOtpForEmail,
    setIsEditable,
    setIsOtpVerify,
    token,
    emailCheck,
    fetchData,
    beneficiariesWithHierarchy,
    isChange,
    setIsNext,
    submitLoading = false,
  } = CompanySetupService();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
    watch,
    setError,
  } = useForm();

  const [viewData, setViewData] = useState(true); // view purpose only
  const [isEditMode, setIsEditMode] = useState(false);
  const [options, setOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orgName, setOrgName] = useState("");
  // timeZone
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // Define state for currency
  const [currency, setCurrency] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailChange, setEmailChange] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track if loading

  // Create a ref for the mobileNumber input field
  const phoneInputRef = useRef(null);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      countries: defaultCountries,
    });

  useEffect(() => {
    register("email", {
      validate: isValidEmail,
    });
  }, [register]);

  useEffect(() => {
    if (registeredData && registeredData.length > 0) {
      const orgData = registeredData[0].organization;
      setValue("mobileNumber", orgData.mobileNumber);
      inputRef.current.value = orgData.mobileNumber;
      setIsLoading(false); // Set loading to false after setting initial value
    }
  }, [inputRef, registeredData, setValue]);

  const appendValues = () => {
    try {
      if (registeredData && registeredData.length > 0) {
        const userData = registeredData[0].user;

        const orgData = registeredData[0].organization;

        // Populate registration fields to display data
        setValue("id", userData.id);
        setValue("email", orgData.email);
        setValue("beneficiaryId", userData.beneficiaryId);
        setValue("organizationName", orgData.organizationName);
        setValue("mobileNumber", orgData.mobileNumber);
        setValue("currency", orgData.currency);
        setValue("gst", orgData.gst);
        setValue("addressLine1", orgData.address?.addressLine1 || "");
        setValue("addressLine2", orgData.address?.addressLine2 || "");
        setValue("addressLine3", orgData.address?.addressLine3 || "");
        setOrgName(orgData.organizationName);
        setCurrency(orgData.currency);
        setEmailValue(orgData.email);

        // Simulate changing the country to trigger update of inputValue
        if (orgData.country) {
          const countryCode = countries.getCode(orgData.country);

          //set the country value, flag and dialCode
          if (countryCode) {
            const countryInfo = {
              name: orgData.country,
              iso2: countryCode.toLowerCase(), // Convert the country code to lowercase
            };
            setCountry(countryInfo.iso2); // Passing ISO2 code directly
          } else {
            console.warn(`Country code not found for: ${orgData.country}`);
            //  set a default value or handle the case where the country code is not found
            setCountry("us"); // set a default country
          }
        }
        inputRef.current.value = orgData.mobileNumber;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    appendValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredData, setValue]);

  const regData = registeredData && registeredData[0]?.organization;

  // Modify the onSubmit function to differentiate between actions
  const onSubmit = async (data) => {
    try {
      if (!emailChange || isVerify) {
        data.country = country.name;
        data.mobileNumber = inputRef.current.value;

        if (regData) {
          data.deleted = regData.deleted;
          data.organizationId = regData.organizationId;
        } else {
          // Optionally, you can set default values or handle the error in another way
          data.deleted = false;
          data.organizationId = null;
        }

        const countryName = country.name;
        const currency = data.currency;
        const gst = data.gst;
        const email = data.email;

        // Remove organizationName & currency field from data object
        delete data.organizationName;
        delete data.currency;
        delete data.timeZone;

        const address = {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          addressLine3: data.addressLine3,
        };

        delete data.addressLine1;
        delete data.addressLine2;
        delete data.addressLine3;
        delete data.gst;
        delete data.otp;

        const mainData = { ...data, address };
        setIsVerify(false);
        setIsEditMode(false);
        setEmailChange(false);

        // Call onCompanySetup with mainData
        onCompanySetup(
          mainData,
          currency,
          selectedTimezone,
          gst,
          email,
          countryName,
          country
        ); // Pass the data object to onCompanySetup
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const initialCountry = country.iso2.toUpperCase();
    const initialTimezones = moment.tz
      .names()
      .filter((zone) =>
        moment.tz.zone(zone).countries().includes(initialCountry)
      );

    // Convert timezones to their UTC offsets with descriptive names
    const timezonesWithOffsets = initialTimezones.map((zone) => {
      const offset = moment.tz(zone).format("Z"); // Format the offset as UTC+/-HH:mm
      const description = zone.replace("_", " ").replace("/", "/"); // Replace underscores and slashes for better readability
      return `UTC${offset} ${description}`;
    });

    setTimezones(timezonesWithOffsets);

    if (timezonesWithOffsets.length > 0) {
      const firstTimezone = timezonesWithOffsets[0];
      setSelectedTimezone(firstTimezone);
      setValue("timeZone", firstTimezone);
      trigger("timeZone");
    }

    trigger("mobileNumber");

    // Find the country name based on the country code
    const countryEntry = defaultCountries.find(
      (count) => count[1] === country.iso2
    );
    if (!countryEntry) {
      console.log("Country not found");
      return;
    }

    const countryName = countryEntry[0];
    try {
      const currencyValue = clm.getCurrencyByName(countryName);
      const validCurrencyOptions = [currencyValue].filter(
        (option) => option !== undefined
      );
      // Set options
      setOptions(validCurrencyOptions);
      setValue("currency", currencyValue);
      setCurrency(currencyValue);
      clearErrors("currency");
    } catch (error) {
      console.error("Error fetching currency data:", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  // Use a ref to track mounting status
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false; // Set to false when the component unmounts
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Track if component is mounted
      if (isMounted.current) {
        const firstErrorKey = Object.keys(errors)[0];
        const firstErrorField = errors[firstErrorKey]?.ref;

        if (errors && firstErrorField) {
          if (errors.mobileNumber) {
            inputRef.current.focus(); // Focus on the mobileNumber input field
          } else {
            firstErrorField.focus();
          }
        }
        setIsSubmitted(false); // Reset after focusing
      }
    }, 0); // Use a short delay to allow state update to propagate

    // Cleanup timeout on component unmount or effect re-run
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, errors]);

  const handleCountryChange = (e) => {
    try {
      const newCountry = e.target.value;
      const oldDialCode = inputRef.current && inputRef.current.value; // Get the current dial code
      let newDialCode = "";
      setValue("gst", "");
      defaultCountries.forEach((c) => {
        const country = parseCountry(c);
        if (country.iso2 === newCountry) {
          newDialCode = country ? country.dialCode : "";
          return;
        }
      });

      // Remove the old dial code from the mobileNumber number
      let newPhoneNumber = inputValue.replace(`+${oldDialCode}`, "");

      // If the new dial code is not empty, add it to the beginning of the mobileNumber number
      if (newDialCode !== "") {
        newPhoneNumber = `+${newDialCode}`;
      }
      // Update the mobileNumber number state
      inputRef.current.value = newPhoneNumber;

      // Update the selected country
      setCountry(newCountry);

      const newTimezones =
        moment.tz
          .names()
          .filter((zone) =>
            moment.tz.zone(zone).countries().includes(newCountry.toUpperCase())
          ) || [];
      setTimezones(newTimezones);
      setSelectedTimezone(""); // Reset timezone selection

      const defaultTimezone = newTimezones.length > 0 ? newTimezones[0] : "";
      setSelectedTimezone(defaultTimezone); // Set the first timezone as the default
      setValue("timeZone", defaultTimezone); // Update the form value
      setCountry(e.target.value);
      clearErrors("timeZone"); // Clear any errors for the timeZone field
      setValue("mobileNumber", newPhoneNumber);
      trigger("mobileNumber");
    } catch (error) {
      console.error(error);
    }
  };

  function otpVerifyApi() {
    const otpValue = watch("otp");
    // if call api success
    otpVerification(watch("email"), otpValue); // Pass the data object to onCompanySetup
  }
  const orgId = ORG_ID();

  function emailVerifyApi() {
    // if call api success
    generateOtpForEmail(watch("email"), orgId); // Pass the data object to onCompanySetup
  }

  // Real-time GST validation function
  const handleGSTChange = (gstin) => {
    try {
      // Use the validator to check if the GST number is valid
      const isValid = GstinValidator.isValidGSTNumber(gstin);
      if (isValid) {
        return isValid;
      } else {
        return "Invalid GST number";
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //overlay in logoUpload
  const handleOverlayEffect = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: hovered ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={
          selectedImage && !selectedImage.includes("Image Not Found")
            ? handlePopoverOpen
            : null
        } // Open popover on overlay click
      >
        <CameraAltOutlined
          sx={{
            color: "#fff",
            fontSize: "2rem",
          }}
        />
      </div>
    );
  };

  // Define the handleButtonClick function to set `isNext` and trigger the form submission
  const handleButtonClick = (next = false) => {
    try {
      setIsSubmitted(true);
      setIsNext(next); // Set whether to navigate after update

      if (!isEditMode) {
        // Update
        beneficiariesWithHierarchy();
        setIsEditMode(true);
        setViewData(false);
      } else {
        const phoneValidation = validatePhoneNumber(
          watch("mobileNumber"),
          country
        );

        if (
          watch("currency") &&
          !errors.mobileNumber &&
          !errors.gst &&
          phoneValidation === true &&
          selectedTimezone &&
          !verifyOtp
        ) {
          if (emailChange && !isVerify) {
            toast.error(VERIFY_EMAIL);
          } else if (!errors.email && !errors.currency && !errors.timeZone) {
            setIsEditMode(false);
            setViewData(true);
            setVerifyOtp(false);
          }
        }
        setValue("otp", "");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Define the cancel button click handler
  const handleCancelClick = () => {
    setIsEditMode(false);
    setViewData(true);
    setVerifyOtp(false);
    setIsVerify(false);
    setEmailChange(false);
    appendValues();
    clearErrors("currency");
    clearErrors("timeZone");
    clearErrors("email");
    clearErrors("mobileNumber");
    clearErrors("organizationName");
    clearErrors("gst");
    setValue("otp", "");
    fetchData();
  };

  const otpVerification = async (email, value) => {
    try {
      const res = await ApiService.otpVerifications(email, value, token);

      if (res && res.status === 200) {
        setVerifyOtp(false);
        setIsVerify(true);
        setIsEditable(true);
      } else {
        setError("otp", {
          type: "manual",
          message: "Invalid OTP",
        });
      }
    } catch (error) {
      setIsOtpVerify(false);
    }
  };

  const fieldRefs = useRef({});

  const handleEmailChange = (enteredValue) => {
    try {
      const trimmedEnteredValue = enteredValue.trim();
      const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();

      // Convert all emails in the array to lowercase for comparison
      const ValidEmail = emailCheck.map((email) => email.toLowerCase());

      // Check if lowercasedEnteredValue exists in the emailCheck array
      const isIncluded = ValidEmail.includes(lowercasedEnteredValue);
      console.log("adminEmail", emailCheck); // prints the emailCheck array

      if (!isIncluded) {
        setEmailChange(true);
        setIsVerify(false);
        return VERIFY_ADMIN_EMAIL;
      } else {
        return true; // Return true for a valid input
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      sx={{ alignItems: "center", justifyContent: "space-between", p: 2 }}
    >
      {/* Loader displayed while submitting the form */}
      <FullScreenLoader loading={submitLoading} />

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit, (errors) =>
          orderErrorFocus(fields, errors, fieldRefs)
        )}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={5}>
            <Typography variant="h5">
              {isEditMode ||
              !currency ||
              !selectedTimezone ||
              errors.mobileNumber
                ? "Edit"
                : ""}
              &nbsp;Company Setup
            </Typography>
          </Grid>

          {/* Right-aligned buttons */}
          <Grid
            item
            xs={12}
            sm={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* Update and Next button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(true)} // Update and navigate action
              sx={{ mr: 1, display: isEditMode ? "block" : "none" }}
            >
              Update & Next
            </Button>

            {/* Update button */}
            <Button
              type={!emailChange && isEditMode ? "button" : "submit"}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick(false)} // Regular update action
              startIcon={
                isEditMode ? <SaveAsTwoTone /> : currency && <EditTwoTone />
              }
              sx={{ mr: isEditMode ? 1 : 0 }}
            >
              {isEditMode ? "Update" : currency && "Edit"}
            </Button>

            {/* Cancel button */}
            <Button
              variant="outlined"
              sx={{ display: isEditMode ? "block" : "none" }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Grid container columnSpacing={1}>
          {fields.map((field, index) => (
            <Grid
              alignItems="center"
              item
              xs={12}
              key={index}
              md={
                field.name === "email" && isEditMode
                  ? 6
                  : field.isButton && !isVerify && isEditMode
                  ? 3
                  : field.isButton && !verifyOtp && emailChange
                  ? 6
                  : field.isButton
                  ? 3
                  : field.isOtp
                  ? 3
                  : 12
              }
              style={{
                display:
                  field.isButton ||
                  (field.isOtp && verifyOtp) ||
                  field.name === "currency" ||
                  field.name === "timeZone" ||
                  field.name === "email" ||
                  field.name === "mobileNumber" ||
                  field.name === "organizationName" ||
                  field.name === "gst" ||
                  field.name === "addressLine1" ||
                  field.name === "addressLine2" ||
                  field.name === "addressLine3"
                    ? "block"
                    : "none",
              }}
            >
              {
                // Check if the field name is "currency"
                field.name === "currency" || field.name === "timeZone" ? (
                  <Autocomplete
                    key={field.name}
                    disableClearable
                    options={field.name === "currency" ? options : timezones} // Provide correct options based on field name
                    value={
                      options.includes(watch(field.name))
                        ? watch(field.name)
                        : timezones.includes(watch(field.name))
                        ? watch(field.name)
                        : null
                    }
                    style={{
                      pointerEvents:
                        viewData || (field.name === "currency" && isChange)
                          ? "none"
                          : "auto", // Remove hover effect when readOnly
                    }}
                    onChange={(event, newValue) => {
                      if (field.name === "currency") {
                        if (options.includes(newValue)) {
                          setValue("currency", newValue);
                          setCurrency(newValue);
                          clearErrors("currency");
                        }
                      } else if (field.name === "timeZone") {
                        if (timezones.includes(newValue)) {
                          setValue("timeZone", newValue);
                          setSelectedTimezone(newValue);
                          clearErrors("timeZone");
                        }
                      }
                      setIsEditMode(true);
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        required={field.required}
                        fullWidth
                        label={field.label}
                        name={field.name}
                        autoComplete={field.autoComplete}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          style: {
                            pointerEvents:
                              viewData ||
                              (field.name === "currency" && isChange)
                                ? "none"
                                : "auto",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputProps: {
                            ...params.inputProps,
                            readOnly:
                              viewData ||
                              (field.name === "currency" && isChange),
                          },
                        }}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        FormHelperTextProps={{ sx: { mb: -3 } }}
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Set ref
                      />
                    )}
                  />
                ) : field.isButton ? (
                  <>
                    {!isVerify && isEditMode ? (
                      <DialogActions className="mt-2 ">
                        <Button
                          type="button"
                          disabled={
                            isEditMode && emailChange && !errors.email
                              ? false
                              : true
                          }
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (!verifyOtp) {
                              emailVerifyApi();
                              setVerifyOtp(true);
                            } else {
                              otpVerifyApi();
                            }
                          }}
                        >
                          {verifyOtp && isEditMode
                            ? "Verify OTP"
                            : "Verify Email"}
                        </Button>
                      </DialogActions>
                    ) : (
                      !verifyOtp &&
                      emailChange && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mt: { md: 2.5 },
                            px: { xs: 1 },
                          }}
                        >
                          OTP verified successfully{" "}
                          <CheckCircleTwoTone color="success" />
                        </Typography>
                      )
                    )}
                  </>
                ) : field.isOtp ? (
                  verifyOtp ? (
                    <TextField
                      key="otp"
                      margin="normal"
                      size="small"
                      required
                      fullWidth
                      label="otp"
                      name="otp"
                      autoComplete={field.autoComplete}
                      type="text"
                      {...register("otp", {
                        required: "OTP is required", // Ensure this returns a string message
                      })}
                      error={!!errors.otp}
                      helperText={errors.otp ? errors.otp.message : ""}
                      FormHelperTextProps={{ sx: { mb: -3 } }}
                      onBlur={() => {
                        trigger("otp"); // Trigger validation on blur
                      }}
                    />
                  ) : null
                ) : (
                  <TextField
                    key={field.name}
                    margin="normal"
                    size="small"
                    required={field.required}
                    fullWidth
                    label={
                      field.name === "gst"
                        ? currency === INDIAN_CURRENCY
                          ? field.label
                          : "CIN(Company Identification Number)"
                        : field.label
                    }
                    name={field.name}
                    autoComplete={field.autoComplete}
                    type={field.type}
                    autoFocus={field.autoFocus}
                    rows={field.rows || 1}
                    multiline={field.multiline || false}
                    inputRef={(el) => {
                      // Assign ref conditionally based on the field name
                      if (field.name === "mobileNumber" && phoneInputRef) {
                        phoneInputRef.current = el;
                      }

                      // Also store in fieldRefs for general use
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Combined ref logic
                    InputProps={{
                      sx: {
                        "& textarea": {
                          resize: "vertical", // Restrict resizing to vertical only
                        },
                      },
                      readOnly: viewData || field.name === "organizationName",
                      style: {
                        pointerEvents:
                          (field.name === "email" && isVerify) ||
                          field.name === "organizationName" ||
                          viewData
                            ? "none"
                            : "auto",
                      },
                      ...(field.name === "mobileNumber"
                        ? {
                            style: {
                              pointerEvents: viewData ? "none" : "auto",
                            },
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  marginRight: "2px",
                                  marginLeft: "-8px",
                                }}
                              >
                                <Select
                                  disabled={isChange} // Disable the Select component when isChange is true
                                  MenuProps={{
                                    style: {
                                      height: "300px",
                                      width: "360px",
                                      top: "10px",
                                      left: "-34px",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                  }}
                                  sx={{
                                    width: "max-content",
                                    fieldset: {
                                      display: "none",
                                    },
                                    '&.Mui-focused:has(div[aria-expanded="false"]) fieldset':
                                      {
                                        border: "none", // Ensure the border stays removed when focused
                                      },
                                    ".MuiSelect-select": {
                                      padding: "8px",
                                      paddingRight: "24px !important",
                                    },
                                    svg: {
                                      right: 0,
                                    },
                                  }}
                                  value={country.iso2}
                                  onChange={handleCountryChange} // Update the mobileNumber number when country changes
                                  renderValue={(value) => (
                                    <FlagImage
                                      iso2={value}
                                      style={{ display: "flex" }}
                                    />
                                  )}
                                >
                                  {defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                      <MenuItem
                                        key={country.iso2}
                                        value={country.iso2}
                                      >
                                        <FlagImage
                                          iso2={country.iso2}
                                          style={{ marginRight: "8px" }}
                                        />
                                        <Typography marginRight="8px">
                                          {country.name}
                                        </Typography>
                                        <Typography color="gray">
                                          +{country.dialCode}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </InputAdornment>
                            ),
                            value: !isLoading
                              ? inputRef.current?.value || ""
                              : inputValue, // Use loading value or state value
                            onChange: (e) => {
                              const value = e.target.value;
                              handlePhoneValueChange(e);
                              setIsLoading(true); // Set loading to false after setting initial value
                              setValue(field.name, value); // Update the form value
                              trigger(field.name); // Trigger validation on change
                            },
                            inputRef: inputRef,
                          }
                        : {}),
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      pattern: field.name === "email" ? isValidEmail : null,
                      validate: {
                        isValidEmail: (value) =>
                          field.name === "email" ? isValidEmail(value) : true,
                        handleEmailChange: (value) =>
                          field.name === "email"
                            ? handleEmailChange(value)
                            : true,
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        validatePhoneNumber: (value) =>
                          field.name === "mobileNumber" && registeredData
                            ? validatePhoneNumber(value, country)
                            : true,
                        handleGSTChange: (value) =>
                          currency === INDIAN_CURRENCY &&
                          watch("gst") !== undefined &&
                          watch("gst") !== null &&
                          watch("gst") !== "" &&
                          field.name === "gst"
                            ? handleGSTChange(value)
                            : true,
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    FormHelperTextProps={{ sx: { mb: -3 } }}
                    InputLabelProps={{
                      style: {
                        pointerEvents: "none",
                      },
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                      const trimmedEnteredValue = e.target.value.trim();
                      const lowercasedEnteredValue =
                        trimmedEnteredValue.toLowerCase();
                      const isEqual =
                        emailValue.toLowerCase() === lowercasedEnteredValue;
                      if (
                        isEqual ||
                        e.target.value === "" ||
                        isValidEmail(e.target.value) !== true
                      ) {
                        setVerifyOtp(false);
                        setValue("otp", "");
                        clearErrors("otp");
                        field.name === "email" && setEmailChange(false);
                      } else {
                        field.name === "email" && setEmailChange(true);
                      }
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                  />
                )
              }
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2} px={2} py={3}>
          <InputLabel>Logo:</InputLabel>
          <Grid item xs={12}>
            {selectedImage && !selectedImage.includes("Image Not Found") ? (
              <div
                style={{
                  position: "relative",
                  width: "auto",
                  height: "auto",
                  maxWidth: "10vw",
                  maxHeight: "13vh",
                  cursor: isEditMode ? "pointer" : "default",
                  overflow: "hidden",
                  display: "inline-block",
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                <img
                  src={selectedImage}
                  alt="Company Logo"
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "10vw",
                    maxHeight: "13vh",
                    transition: "transform 0.3s ease-in-out",
                    position: "relative",
                  }}
                  onClick={(e) => {
                    if (isEditMode) {
                      handlePopoverOpen(e);
                      // Prevent triggering file input click when showing popover
                      e.stopPropagation();
                    }
                  }}
                />
                {isEditMode && handleOverlayEffect()}
              </div>
            ) : (
              <Avatar
                variant="rounded"
                sx={{
                  position: "relative",
                  width: { xs: "15vw", sm: "12vw", md: "10vw" },
                  height: { xs: "15vh", sm: "12vh", md: "10vh" },
                  cursor: isEditMode ? "pointer" : "default",
                  transition: "transform 0.3s ease-in-out",
                  overflow: "hidden",
                  "&:hover": {
                    transform: isEditMode ? "scale(1.05)" : "none",
                  },
                }}
                onClick={handleAvatarClick}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {orgName[0]}
                {/* Overlay Icon and Background */}
                {isEditMode && handleOverlayEffect()}
              </Avatar>
            )}
          </Grid>
          <input
            type="file"
            accept="image/*"
            hidden
            ref={fileInputRef} // Attach ref to the file input
            onChange={handleFileChangeLogo}
            disabled={isEditMode ? false : currency && selectedTimezone && true}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 2 }} // Ensure the popover appears above the overlay
          >
            <MenuItem
              onClick={() => {
                fileInputRef.current?.click(); // Trigger file input on "Change Image" click
                handlePopoverClose(); // Close popover after action
              }}
            >
              <EditTwoTone sx={{ fontSize: 35, color: "blue", pr: 2 }} /> Change
              company logo
            </MenuItem>
            <MenuItem
              onClick={() => {
                // Reset file input after processing
                if (fileInputRef && fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                setSelectedImage(null);
                setSelectedFileLogo({});
                /* Handle Remove Image */
                handlePopoverClose(); // Close popover after action
              }}
            >
              <DeleteTwoTone sx={{ fontSize: 35, color: "red", pr: 2 }} />{" "}
              Delete company logo
            </MenuItem>
          </Popover>
        </Grid>
      </Box>
    </Grid>
  );
}
