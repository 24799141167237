// Phone Number Validation
export const validatePhoneNumber = (value, country) => {
  try {
    // Ensure value is defined and is a string
    if (!value || typeof value !== "string") {
      throw new Error("Invalid phone number");
    }

    // Ensure country and country.dialCode are defined
    if (!country || !country.dialCode) {
      throw new Error("Invalid country or dialCode");
    }

    // Extract dial code from the country object
    const dialCode = country.dialCode;

    // Remove dial code from the value
    const phoneNumberWithoutDialCode = value
      .replace(new RegExp(`^\\+${dialCode}\\s*`), "")
      .replace(/\D/g, ""); // Remove non-digit characters

    // Determine the format
    let format = "";
    if (typeof country.format === "string") {
      format = country.format;
    } else if (typeof country.format === "object") {
      // Match against patterns in the object
      for (const pattern in country.format) {
        if (
          pattern !== "default" &&
          new RegExp(pattern).test(phoneNumberWithoutDialCode)
        ) {
          format = country.format[pattern];
          break;
        }
      }
      // Use default format if no pattern matched
      format = format || country.format.default || "";
    } else {
      console.warn(
        "Country format is not a string or an object. Defaulting to an empty string."
      );
    }

    // Calculate the expected length based on the chosen format
    const expectedLength = format.replace(/[^.]/g, "").length;

    // Validate phone number length
    if (phoneNumberWithoutDialCode.length >= expectedLength && format !== "") {
      return true;
    } else if (format === "") {
      if (phoneNumberWithoutDialCode.length >= 10) {
        return true;
      } else {
        throw new Error("Invalid phone number");
      }
    } else {
      throw new Error("Invalid phone number");
    }
  } catch (error) {
    console.error("Validation error:", error.message);
    return false || "Invalid phone number";
  }
};
