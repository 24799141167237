import PolicyPage from "./PolicyPage";
import { privacyPolicyContent } from "./privacyPolicyContent";
import { termsConditionsContent } from "./termsConditionsContent";

export function PrivacyPolicy() {
  return (
    <PolicyPage title="Privacy Policy" contentData={privacyPolicyContent} />
  );
}

export function TermsConditions() {
  return (
    <PolicyPage
      title="Terms and Conditions"
      contentData={termsConditionsContent}
    />
  );
}
