export const S1_Heading =
  "Welcome to CompFact, Your Complete Sales Commission Solution";

export const S1_Paragraph =
  " Experience seamless commission management and empowered sales performance with our innovative platform.";

export const S1_ButtonText = "Register Now";

export const S2_Heading = "Empower Your Sales Team, Maximize Performance";

export const S2_Paragraph =
  "At CompFact, we understand the importance of rewarding your sales team for their hard work. Our commission software simplifies the process, allowing you to focus on what matters most – driving sales and growing your business.";

export const Try_Now = "Try Now";

export const L2_S1_Heading =
  "Streamline incentive management and amplify sales performance";

export const L3_S1_Heading =
  "Effortlessly create, manage, and optimize incentive programs";

export const L4_S1_Heading =
  "Simplify sales incentives and focus on what matters most - growth";

export const L5_S1_Heading =
  "Automate incentive payouts and reduce administrative burdens";
