import { useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { GA_MEASUREMENT_ID } from "./components/config/envConfig";

// Define the list of paths to be tracked
const TRACKED_PATHS = [
  "/",
  "/landing",
  "/landing#pricing",
  "/landing2",
  "/landing3",
  "/landing4",
  "/landing5",
  "/register",
  "/register_success",
  "/sign_in",
  "/terms_conditions",
  "/privacy_policy",
];

export default function GoogleAnalytics() {
  const location = useLocation();
  const isInitialized = useRef(false); // Ensure initialization happens only once
  const previousPath = useRef(null); // Track the previous path to avoid duplicate tracking

  useEffect(() => {
    // Initialize Google Analytics only once
    if (!isInitialized.current) {
      try {
        ReactGA.initialize(GA_MEASUREMENT_ID);
        isInitialized.current = true;
      } catch (error) {
        console.error("Failed to initialize Google Analytics:", error.message);
      }
    }

    // Track pageviews and searches only if the path changes and is in the tracked list
    const { pathname, search } = location;
    if (TRACKED_PATHS.includes(pathname) && pathname !== previousPath.current) {
      try {
        // Include search query in pageview tracking if present
        const fullPath = `${pathname}${search}`;
        ReactGA.send({ hitType: "pageview", page: fullPath });
        console.log(`Pageview sent for: ${fullPath}`);
        previousPath.current = pathname; // Update the last tracked path
      } catch (error) {
        console.error(
          "Error sending pageview to Google Analytics:",
          error.message
        );
      }
    }
  }, [location, location.pathname, location.search]); // Include search in the dependency array

  return null; // This component doesn't render any UI
}
