import { createTheme } from "@mui/material";

const font = "Source Sans 3,'sans-serif'";

const Themes = createTheme({
  palette: {
    primary: {
      // main: "#6200ea",
      main: "#0066ff",
      // main: "#0C1E3A",
    },
  },
  typography: {
    fontFamily: font,
    gradient1: {
      fontWeight: "bold",
      backgroundImage: "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#1e76fa", // Set the background color for contained buttons
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "rgba(0, 0, 0, 0.87)",
          backgroundColor: "#fff",
        },
      },
    },
  },
});

export default Themes;
