export const featuresLeft = [
  {
    title: "Customizable Commission Structures",
    description:
      "Tailor commissions to fit your unique sales model. Whether it's tiered, flat rate, or a combination, CompFact adapts to your needs.",
  },
  {
    title: "Real-time Reporting",
    description:
      "Stay informed with up-to-the-minute insights. Track sales performance, commissions earned, and payouts effortlessly.",
  },
  {
    title: "Automated Calculations",
    description:
      "Say goodbye to manual calculations and errors. Our software automates commission calculations, saving you time and resources.",
  },
];

export const featuresRight = [
  {
    title: "Transparent Payouts",
    description:
      "Foster trust and transparency with your team. Easily communicate commission structures and payouts, ensuring everyone is on the same page.",
  },
  {
    title: "Seamless Data Integration",
    description:
      "Compfact empowers users with effortless data synchronization across disparate data sources, ensuring absolute data consistency and integrity throughout all connected applications.",
  },
  {
    title: "Mobile Accessibility",
    description:
      "Access CompFact anytime, anywhere. Our mobile-friendly platform allows your team to view commissions on the go.",
  },
];
