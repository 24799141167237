import Error400 from "../components/ErrorScreens/error400";
import Error403 from "../components/ErrorScreens/error403";
import Error404 from "../components/ErrorScreens/error404";
import Error500 from "../components/ErrorScreens/error500";
import Error503 from "../components/ErrorScreens/error503";
import UncaughtError from "../components/ErrorScreens/uncaughtError";
import VerticalLayout from "../components/layouts/vertical";
import {
  AccessDenied,
  ForgotSuccess,
  IdleLogout,
  RegisterSuccess,
  SessionLogout,
  SignUpSuccess,
  TokenVerified,
} from "../components/MessageScreens";
import DemoUserInfo from "../components/MessageScreens/demoUserInfo";
import PaymentFailed from "../components/MessageScreens/paymentFailed";
import PaymentSuccess from "../components/MessageScreens/paymentSuccess";
import ForgotPwd from "../components/pages/ForgotPwd";
import LandingPage from "../components/pages/Landing";
import {
  LandingScreen2,
  LandingScreen3,
  LandingScreen4,
  LandingScreen5,
} from "../components/pages/Landing2";
import {
  PrivacyPolicy,
  TermsConditions,
} from "../components/pages/PrivacyPolicy";
import RecoverPwd from "../components/pages/RecoverPwd";
import Register from "../components/pages/Register";
import SignIn from "../components/pages/SignIn";
import MfaVerify from "../components/pages/SignIn/MfaVerify";
import SignUp from "../components/pages/SignUp";

export const publicRoutesConfig = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/access_denied",
    element: <AccessDenied />,
  },
  {
    path: "/demoUser_info",
    element: <DemoUserInfo />,
    props: ["setIsAuthenticated", "setRes"],
  },
  {
    path: "/error_400",
    element: <Error400 />,
  },
  {
    path: "/error_403",
    element: <Error403 />,
  },
  {
    path: "/error_404",
    element: <Error404 />,
  },
  {
    path: "/error_500",
    element: <Error500 />,
  },
  {
    path: "/error_503",
    element: <Error503 />,
  },
  {
    path: "/forgot_pwd",
    element: <ForgotPwd />,
  },
  {
    path: "/forgot_success",
    element: <ForgotSuccess />,
  },
  {
    path: "/landing",
    element: <LandingPage />,
  },
  {
    path: "/landing2",
    element: <LandingScreen2 />,
  },
  {
    path: "/landing3",
    element: <LandingScreen3 />,
  },
  {
    path: "/landing4",
    element: <LandingScreen4 />,
  },
  {
    path: "/landing5",
    element: <LandingScreen5 />,
  },
  {
    path: "/mfa-verify",
    element: <MfaVerify />,
    props: ["setIsAuthenticated", "setRes", "setUserRoleAccess"],
  },
  {
    path: "/payment_failed",
    element: <PaymentFailed />,
  },
  {
    path: "/privacy_policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/payment_success",
    element: <PaymentSuccess />,
  },
  {
    path: "/recover_pwd",
    element: <RecoverPwd />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register_success",
    element: <RegisterSuccess />,
  },
  {
    path: "/signup_success",
    element: <SignUpSuccess />,
  },
  {
    path: "/session_logout",
    element: <SessionLogout />,
  },
  {
    path: "/session_expired",
    element: <IdleLogout />,
  },
  {
    path: "/sign_in",
    element: <SignIn />,
    props: ["setIsAuthenticated", "setRes", "setUserRoleAccess"],
  },
  {
    path: "/sign_up",
    element: <SignUp />,
  },
  {
    path: "/terms_conditions",
    element: <TermsConditions />,
  },
  {
    path: "/token_verified",
    element: <TokenVerified />,
  },
  {
    path: "/uncaught_error",
    element: <UncaughtError />,
  },
  {
    path: "/vertical",
    element: <VerticalLayout />,
  },
];
