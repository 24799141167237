import { Box, Container, Grid, Typography } from "@mui/material";
import GradientButtonWithLink from "../Landing/GradientButton";

export default function HeroSection({
  heading,
  paragraph,
  coverImage,
  asideImage,
  buttonText,
  imgRight = false,
}) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${coverImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "scroll",
        padding: {
          xs: "2rem 1rem",
          sm: "3rem 2rem",
        },
        bgcolor: "white",
      }}
    >
      <Container>
        <Grid
          container
          spacing={4}
          sx={{
            minHeight: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "space-between" }, // Center for sm, normal layout for md and up
            textAlign: { xs: "center", md: "left" }, // Center text for sm, left-align for md and up
          }}
        >
          {imgRight ? (
            <>
              {/* Left Section: Heading */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" }, // Center for sm, left-align for md and up
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    background:
                      "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: "bold",
                    marginBottom: 2,
                    fontSize: {
                      xs: "1.5rem", // Small screens
                      sm: "2rem", // Medium screens
                      md: "2.5rem", // Large screens
                      lg: "3rem", // Extra-large screens
                    },
                  }}
                >
                  {heading}
                </Typography>

                {/* Image for xs and sm screens */}
                <Box
                  component="img"
                  src={asideImage}
                  alt="Section"
                  sx={{
                    display: { xs: "block", md: "none" }, // Show only for xs and sm
                    maxWidth: "100%",
                    height: "auto",
                    margin: "1rem 0",
                  }}
                />

                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    color: "#333",
                    fontSize: {
                      xs: "0.875rem", // Small screens
                      sm: "1rem", // Medium screens
                      md: "1.125rem", // Large screens
                      lg: "1.25rem", // Extra-large screens
                    },
                  }}
                >
                  {paragraph}
                </Typography>
                {buttonText && (
                  <GradientButtonWithLink
                    to={"/register"}
                    sx={{ mt: 2, bgcolor: "none" }}
                  >
                    {buttonText}
                  </GradientButtonWithLink>
                )}
              </Grid>
            </>
          ) : (
            <>
              {/* Left Section: Heading */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: { xs: "none", md: "flex" }, // Hide for xs and sm
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={asideImage}
                  alt="Section"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Grid>
            </>
          )}

          {imgRight ? (
            <>
              {/* Right Section: Image for md and larger screens */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: { xs: "none", md: "flex" }, // Hide for xs and sm
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={asideImage}
                  alt="Section"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Grid>
            </>
          ) : (
            <>
              {/* Right Section: Image for md and larger screens */}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" }, // Center for sm, left-align for md and up
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  gutterBottom
                  sx={{
                    background:
                      "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: "bold",
                    marginBottom: 2,
                    fontSize: {
                      xs: "1.5rem", // Small screens
                      sm: "2rem", // Medium screens
                      md: "2.5rem", // Large screens
                      lg: "3rem", // Extra-large screens
                    },
                  }}
                >
                  {heading}
                </Typography>

                {/* Image for xs and sm screens */}
                <Box
                  component="img"
                  src={asideImage}
                  alt="Section"
                  sx={{
                    display: { xs: "block", md: "none" }, // Show only for xs and sm
                    maxWidth: "100%",
                    height: "auto",
                    margin: "1rem 0",
                  }}
                />

                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    color: "#333",
                    fontSize: {
                      xs: "0.875rem", // Small screens
                      sm: "1rem", // Medium screens
                      md: "1.125rem", // Large screens
                      lg: "1.25rem", // Extra-large screens
                    },
                  }}
                >
                  {paragraph}
                </Typography>
                {buttonText && (
                  <GradientButtonWithLink to={"/register"}>
                    {buttonText}
                  </GradientButtonWithLink>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
