import { Box, Typography, Link } from "@mui/material";

export default function FooterBar() {
  return (
    <>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="body1" sx={{ fontSize: { xs: 14, sm: 16 } }}>
          By using our sales commission software, you agree to the terms
          outlined in this{" "}
          <Link href="/terms_conditions" target="_blank">
            Terms and Conditions
          </Link>{" "}
          &amp;{" "}
          <Link href="/privacy_policy" target="_blank">
            Privacy Policy
          </Link>
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          p: { xs: 1, sm: 2 }, // Responsive padding
          textAlign: "center",
          color: "#333",
          fontSize: { xs: 14, sm: 16 }, // Responsive font size
          fontWeight: "650",
        }}
      >
        Copyright All Rights Reserved {new Date().getFullYear()} ©
        <Typography variant="gradient1" component={"span"} ml={1}>
          Compfact
        </Typography>
      </Typography>
    </>
  );
}
