import { cloneElement } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import {
  EmailTwoTone,
  FacebookTwoTone,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";
import {
  CONTACT_MAIL,
  FB_LINK,
  LINKEDIN_LINK,
  YOUTUBE_LINK,
} from "../../config/constants";
import { CfTransLogo } from "../../config/imageConfig";

export default function FooterTop() {
  const socialLinks = [
    { href: FB_LINK, icon: <FacebookTwoTone />, hoverColor: "#1877F2" },
    { href: YOUTUBE_LINK, icon: <YouTube />, hoverColor: "#FF0000" },
    { href: LINKEDIN_LINK, icon: <LinkedIn />, hoverColor: "#0077B5" },
  ];

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
          p: { xs: 3, sm: 4, md: 5 }, // Responsive padding
          flexDirection: { xs: "column", md: "row" }, // Column layout on small screens, row on larger screens
          alignItems: "center", // Center items vertically
          textAlign: { xs: "center", md: "left" }, // Center text on small screens
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
            px: { xs: 2, md: 5 }, // Responsive padding
          }}
        >
          <img
            src={CfTransLogo}
            alt="compFact logo"
            style={{ height: "8vh", width: "auto", maxWidth: "100%" }} // Adjusted for better scaling
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: 12, sm: 14, md: 16 }, // Responsive font size
              color: "white",
              mt: 2,
              mx: { xs: 1 }, // Margin adjustments for responsiveness
            }}
          >
            Experience seamless commission management and empowered sales
            performance with our innovative platform. Transform your sales
            process, save time, and boost productivity with our automated
            commission solution.
          </Typography>
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", md: "block" }, // Hide on small screens
            borderColor: "rgba(255, 255, 255, 0.4)", // Light white color
            mx: { xs: 0, md: 2 }, // Margin adjustments
          }}
        />

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            pt: { xs: 3, sm: 4, md: 5 }, // Responsive padding
            pl: { xs: 0, md: 2 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "650",
              lineHeight: 1.5,
              color: "white",
              fontSize: { xs: 16, sm: 18, md: 20 }, // Responsive font size
              mb: 1, // Margin bottom
            }}
          >
            Connect with us
          </Typography>
          <Divider
            textAlign="left"
            flexItem
            sx={{
              display: { xs: "block", md: "none" }, // Display only on small screens
              borderColor: "white",
              borderWidth: "1px",
              mb: 2, // Margin bottom for spacing
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: 14, sm: 16 }, // Responsive font size
              color: "white",
              mb: 1,
              alignItems: "center",
            }}
          >
            <EmailTwoTone sx={{ fontSize: 20, mr: 1 }} />
            {CONTACT_MAIL}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              gap: { xs: 1, sm: 2 },
              mt: 1,
            }}
          >
            {socialLinks.map(({ href, icon, hoverColor }, index) => (
              <IconButton
                key={index}
                href={href}
                target="_blank"
                sx={{
                  color: "white",
                  "&:hover": { bgcolor: hoverColor },
                }}
              >
                {cloneElement(icon, {
                  sx: { fontSize: { xs: 24, sm: 30 } },
                })}
              </IconButton>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
