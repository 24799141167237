// Website Url
export const WEB_URL = "https://compfact.co/";

export const WEB_LINK = "www.compfact.co";

export const YOUTUBE_LINK = "https://www.youtube.com/@compfact-cf";

export const FB_LINK = "https://www.facebook.com/profile.php?id=61570041698575";

export const LINKEDIN_LINK = "https://www.linkedin.com/in/compfact-cf";

export const CONTACT_MAIL = "contact@compfact.co";

export const SALES_MAIL = "sales@compfact.co";

export const INFO_MAIL = "info@compfact.co";

export const DEMO_MAIL = "demo@compfact.co";

export const FREE_PLAN = "Free Trail";

// Admin name
export const ADMIN_NAME = "Admin";

// Currency Name
export const INDIAN_CURRENCY = "INR";
export const US_CURRENCY = "USD";

// Monitor Menu Screens
export const MONITOR_SCREEN = "Monitor";

// Super Admin Menu Screens
export const PLAN_SCREEN = "Plan";
export const PAYMENT_HISTORY_SCREEN = "PaymentHistory";

// Both Monitor & Super Admin Menu Screens
export const USER_ACTIVITY_SCREEN = "UserActivity";
export const TICKET_SCREEN = "Ticket";

// Admin Menu Screens
export const ACCESS_SCREEN = "Access"; //routing change needed PageAccess
export const AUDIT_LOG_SCREEN = "AuditLog";

// Admin & Other Users Menu Screens
export const DASHBOARD_SCREEN = "Dashboard";

// Setting Menu Screens
export const COMPANY_SETUP_SCREEN = "CompanySetup";
export const ROLE_SCREEN = "Role";
export const TYPE_SCREEN = "Type";
export const FIELD_MAPPING_SCREEN = "FieldMapping";

// Master Menu Screens
export const BENEFICIARY_SCREEN = "Beneficiary";
export const CUSTOMER_SCREEN = "Customer";
export const PRODUCT_SCREEN = "Product";

export const TRANSACTION_SCREEN = "Transaction";

// Commission Setting Menu Screens
export const PAY_FREQUENCY_SCREEN = "PayFrequency";
export const VARIABLE_SCREEN = "Variable";
export const COMMISSION_SCREEN = "Commission";
export const ASSIGN_COMMISSION_SCREEN = "AssignCommission";

// Scheduler Menu Screens
export const SCHEDULE_SCREEN = "Schedule";
export const SCHEDULE_STATUS_SCREEN = "ScheduleStatus";

// Report Menu Screens
export const REPORT_SCREEN = "Report"; // routing change needed commReport
export const REPORT_R_SCREEN = "ReportR";
export const OTHER_REPORT_SCREEN = "OtherReports";

export const COMMISSION_BY_PRODUCT_SCREEN = "CommByProduct";
export const COMMISSION_BY_CUSTOMER_SCREEN = "CommByCustomer";

// Billing Menu Screens
export const BILLING_SCREEN = "Billing"; // routing change needed subscription
export const PRICING_SCREEN = "Pricing";

// Display Screen Names
export const VARIABLE_DISPLAY_NAME = "Variables";
export const SCHEDULE_STATUS_DISPLAY_NAME = "Schedule Status";
export const PAY_FREQUENCY_DISPLAY_NAME = "Pay Frequencies";
export const PAY_FREQUENCY_FORM_DISPLAY_NAME = "Pay Frequency";
export const PAYMENT_HISTORY_DISPLAY_NAME = "Payment History";
export const USER_ACTIVITY_DISPLAY_NAME = "User Activity";
export const AUDIT_DISPLAY_NAME = "Audit Log";
export const CUSTOMER_DISPLAY_NAME = "Customers";
export const PRODUCT_DISPLAY_NAME = "Products";
export const PLAN_DISPLAY_NAME = "Plans";

export const COMMISSION_BY_PRODUCT_DISPLAY_NAME = "Commission by Products";
export const COMMISSION_BY_CUSTOMER_DISPLAY_NAME = "Commission by Customers";
export const COMMISSION_DISPLAY_NAME = "Commissions";
export const ASSIGN_COMMISSION_DISPLAY_NAME = "Assign Commission";
export const TRANSACTION_DISPLAY_NAME = "Transactions";
export const BENEFICIARY_DISPLAY_NAME = "Beneficiaries";
