import { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import {
  ImgEmpower,
  ImgFeature,
  ImgSalesBanner,
  ImgSlideTrans,
  ImgSlideWhite,
  ImgWhyScom,
} from "../../config/imageConfig";
import TopBarWithDrawer from "../Landing2/TopBarWithDrawer";
import Section1 from "./section1";
import Section2 from "./section2";
import KeyFeatures from "./keyFeatures";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import FooterTop from "./FooterTop";
import FooterBar from "./Footer";

export default function LandingPage() {
  const pricingRef = useRef(null);

  useEffect(() => {
    // Smooth scroll to the PricingHome component if the URL hash is #pricing
    if (window.location.hash === "#pricing") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Container disableGutters maxWidth="xl">
        {/* Header Section */}
        <TopBarWithDrawer />

        {/* First Section */}
        <Section1
          bgImgSlideTop={ImgSlideTrans}
          bgImgSalesBanner={ImgSalesBanner}
        />

        {/* Second Section */}
        <Section2 bgImgEmpower={ImgEmpower} />

        {/* Third Section */}
        <KeyFeatures bgImgSlide={ImgSlideWhite} bgImgFeature={ImgFeature} />

        {/* Fourth Section */}
        <Section4 bgImgWhyCf={ImgWhyScom} />

        {/* Fifth Section */}
        <Section5 bgImgSlide={ImgSlideWhite} />

        {/* Sixth Section */}
        <Section6 pricingRef={pricingRef} bgImgSlide={ImgSlideWhite} />

        {/* About Us Section */}
        <AboutUs />

        {/* Contact Us Section */}
        <ContactUs />

        {/* Footer Top Section */}
        <FooterTop />

        {/* Footer Section */}
        <FooterBar />
      </Container>
    </>
  );
}
