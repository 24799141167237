import { CONTACT_MAIL, INFO_MAIL, WEB_LINK } from "../../config/constants";

export const privacyPolicyContent = [
  {
    subBody: `Compfact is committed to protecting your privacy. This privacy statement applies solely to the information collected by Compfact through the ${WEB_LINK} site (the “Site”) hosted for Compfact applications; it does not apply to other online or offline Compfact sites, products, or services.`,
  },
  {
    heading: "Information Collection",
    paragraph1:
      "We, Compfact may collect personally identifiable information which may include your name, surname, contact number, email address, location and gender. We may also collect some additional personal or non-personal information in the future.",
    paragraph2:
      "If you visit our site, we collect information about your visit to our site. For example, we may use web analytics tools to retrieve information from your browser, including the site you came from, the search engine(s) and the keywords you used to find our Site, the pages you view within the Site, your browser add-ons, and your browser's width and height.",
    paragraph3:
      "In addition, information about your computer hardware or software may automatically be collected by Compfact. This information can include your IP address, browser type and language, access times and referring Website addresses.",
    paragraph4:
      "In order to offer you a more consistent and personalized experience in your interactions with Compfact, information collected through any one of Compfact services may be combined with information obtained through other Compfact services. We may also supplement the information we collect with information obtained from other companies. For example, we may use services from other companies that enable us to derive a general geographic area based on your IP address to customize certain services to your geographic area.",
  },
  {
    heading: "Information Use",
    paragraph1:
      "Compfact uses your personal information to operate its website and provide you with the services(s) or execute the transaction(s) you have requested or authorized.",
    paragraph2:
      "In support of these uses, Compfact may use your personally identifiable information to provide you with more effective customer service, improve its website and any queries related to Compfact products or services, perform analysis, and make its website easier to use by eliminating the need for you to repeatedly enter the same information or by customizing its website to your preferences or interests.",
    paragraph3:
      "Compfact may, from time to time contact you using your information to provide you with important information about the product or service(s) that you are using, including critical updates and notifications. Unless you ask us not to, we may send you additional information regarding specials, other products and services and changes to this privacy policy. We may also share your data with Compfact trusted partners to send you information about their products and services.",
    paragraph4:
      "Compfact occasionally employs other third-party companies and individuals to provide limited services on our behalf, such as web hosting, packaging, shipping & delivering, IVR, contact centreand answering about products and services, and sending information about our products, special offers, and other new services. We will only provide these third-party companies the personal information they need to deliver the service. However, they are obligated not to disclose or use the information for any other purpose.",
  },
  {
    heading:
      "We may access or disclose information about you, including the content of your communications, in order to:",
    paragraph1:
      "Comply with the law or respond to lawful requests or legal process; protect the rights or property of Compfact or our customers, including the enforcement of our agreements or policies governing your use of the services; or act on a good faith belief that such access or disclosure is necessary to protect the personal safety of Compfact employees, customers or the public. We may also disclose personal information as part of a corporate transaction such as a merger or sale of assets. Information that is collected on the Site may be stored and processed in the India, US, UAE or any other country in which Compfact or its affiliates, subsidiaries, or service providers maintain facilities.",
  },
  {
    heading: "Personal Information Control",
    paragraph1: `We will not share and disclose any of your personal information with third parties except as described in this policy without your permission. We do not sell your personal information at any cost. If you do not wish to receive any promotional e-mail from Compfact sites and services, you can send us an email to ${INFO_MAIL} and request your removal. You may also proactively make choices about the communications you receive through this Site by clicking on the unsubscribing from the marketing emails list. Please be aware that this privacy statement and any choices you make on the Site will not necessarily apply to personal information you may have provided to Compfact in the context of other, separately operated, Compfact products or services.`,
  },
  {
    heading: "Personal Information Access",
    paragraph1:
      "Customer information is collected by Compfact and its vendor for redemption and its associated process. Any data provided by you to us will be displayed back to you. You can review and edit your personal information at this Site by logging on to the microsite site and clicking on the My Profile.",
  },
  {
    heading: "Information Security",
    paragraph1:
      "We, at Compfact value your trust in providing us your personal information, thus we take the security of your personal information very seriously. We have implemented security policies and measures to protect the personal information that we have under our control. We imply certain procedures to maintain the security of your personal data from unauthorized access, use, or disclosure. For example, we store the personal information you provide on computer servers with limited access that are located in controlled facilities.",
  },
  {
    heading: "Cookies Use",
    paragraph1:
      "A cookie is a text file that contains small amount of data that is placed on your hard disk by a web server. One of the primary purposes of cookies is to provide a convenience feature to save your time.",
    paragraph2:
      "Our website uses these cookies to collect information and improve your access to our website. For example, if you personalize a Web page, or navigate within a site, a cookie helps the site to recall your specific information on subsequent visits. This simplifies the process of delivering relevant content, eases site navigation, and so on. When you return to the Web site, the information you previously provided can be retrieved, so you can easily use the site's features that you customized.",
    paragraph3:
      "You have the option to either accept or refuse these cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you are using Internet Explorer, search for “Cookies” in the Help menu to find detailed instructions. If you choose to decline cookies, you may not be able to use interactive features of this or other Web sites that depend on cookies.",
  },
  {
    heading: "Web Beacons Use",
    paragraph1:
      "Pages on this Site may contain electronic images known as Web beacons - sometimes called single-pixel gifs that allows us to count users you have visited those pages or to access certain cookies. We may also include Web beacons in promotional e-mail messages or our newsletters to determine whether messages have been opened and acted upon.",
    paragraph2:
      "Compfact may also employ Web beacons from third parties to help us compile aggregated statistics and determine the effectiveness of our promotional campaigns or other operations of our sites. We prohibit Web beacons on our sites from being used by third parties to collect or access your personal information. We may work with other companies that advertise on our sites to place Web beacons on their sites to allow us to develop statistics on how often clicking on an advertisement on a Compfact site results in a purchase or other action on the advertiser's site.",
  },
  {
    heading: "Privacy Statement Changes",
    paragraph1:
      'We may occasionally update this privacy statement. When we do, we will also revise the "last updated" date at the top of the privacy statement. Your continued use of our products and services after we post any modifications to the privacy policy on this page will constitute your acknowledgement and consent to abide by the modified privacy statement. We encourage you to periodically review this privacy statement to stay informed about how we are helping to protect the personal information we collect.',
  },
  {
    heading: "Contact Information",
    paragraph1: `Compfact welcomes your comments regarding this privacy statement. If you have questions about this statement or believe that we have not adhered to it, please contact us by email at ${INFO_MAIL} or by postal mail at : ${CONTACT_MAIL}`,
  },
];
