import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import error404 from "../../assets/images/errors/Error-404.svg";

export default function Error404() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <img src={error404} alt="Not Found" width={500} height={250} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h1">404</Typography>
              <Typography variant="h4">
                The page you were looking for is not found!
              </Typography>
              <Typography variant="h6">
                You may have mistyped the address or the page may have moved.
              </Typography>
              <Button
                onClick={goBack}
                variant="contained"
                style={{ marginTop: "1rem" }}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
