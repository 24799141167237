import { useNavigate } from "react-router-dom";
import { FREE_PLAN } from "../../../config/constants";
import PricingCard from "../../../../utils/PricingCard";
import { PricingService } from "./service";

export default function PricingHome() {
  // Retrieve pricing plans from PricingService, default to an empty array for safety
  const { pricingPlans = [] } = PricingService();

  // Initialize the navigate function for routing
  const navigate = useNavigate();

  // Handle button click event to navigate to the registration page
  const handleButtonClick = (title = FREE_PLAN) => {
    try {
      // Navigate to register with the selected plan title in state
      navigate("/register", { state: { selectedSubscriptionPlan: title } });
    } catch (error) {
      // Log any error that occurs during navigation
      console.error("Error navigating to register:", error);
    }
  };

  return (
    <>
      {/* Render the Pricing Cards with plans */}
      <PricingCard
        pricingPlans={pricingPlans} // Array of pricing plans to display
        onCardClick={handleButtonClick} // Function to handle button click events in PricingCard
        isPricingHome={true} // Boolean flag to indicate it's the home page for pricing
      />
    </>
  );
}
