import { cloneElement, createElement, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import toast from "react-hot-toast";
import ApiService from "../apis/ApiService";
import {
  ADMIN_NAME,
  ASSIGN_COMMISSION_SCREEN,
  BENEFICIARY_SCREEN,
  COMMISSION_SCREEN,
  COMPANY_SETUP_SCREEN,
  CUSTOMER_SCREEN,
  DASHBOARD_SCREEN,
  FIELD_MAPPING_SCREEN,
  PAY_FREQUENCY_SCREEN,
  PRODUCT_SCREEN,
  REPORT_R_SCREEN,
  REPORT_SCREEN,
  ROLE_SCREEN,
  SCHEDULE_SCREEN,
  SCHEDULE_STATUS_SCREEN,
  TICKET_SCREEN,
  TRANSACTION_SCREEN,
  TYPE_SCREEN,
  VARIABLE_SCREEN,
  COMMISSION_BY_PRODUCT_SCREEN,
  COMMISSION_BY_CUSTOMER_SCREEN,
} from "../components/config/constants";
import {
  EMAIL_ID,
  IP_ADDRESS,
  IS_MONITOR,
  IS_SUPER_ADMIN,
  SESSION_ID,
  USER_ROLE,
} from "../components/config/sessionStorage";

import { events, excludedPaths } from "../components/config/fieldConfig";

import { publicRoutesConfig } from "./publicRoutesConfig";

import MetaTag from "./MetaTags";

// Public Routes
import ResetPwd from "../components/pages/ResetPwd";

// Message Routes
import { AccessDenied } from "../components/MessageScreens";

import HorizontalLayout from "../components/layouts/horizontal";

// Private Routes
import Access from "../components/pages/Access";
import Monitor from "../components/pages/Monitor";

import AssignCommission from "../components/pages/AssignCommission";
import Beneficiary from "../components/pages/Beneficiary";
import FieldMapping from "../components/pages/FieldMapping";
import Commission from "../components/pages/Commission";
import CompanySetup from "../components/pages/CompanySetup";
import Dashboard from "../components/pages/Dashboard";
import Customer from "../components/pages/Customer";
import PayFrequency from "../components/pages/PayFrequency";
import Product from "../components/pages/Product";
import Report from "../components/pages/Report";
import ReportR from "../components/pages/ReportR";
import Role from "../components/pages/Role";
import Schedule from "../components/pages/Schedule";
import Transaction from "../components/pages/Transaction";
import Variables from "../components/pages/Variable";
import Type from "../components/pages/Type";
import Ticket from "../components/pages/Ticket";
import Billing from "../components/pages/Billing";
import Pricing from "../components/pages/Pricing";
import IndividualDashboard from "../components/pages/IndividualDashboard";
import Plan from "../components/pages/Plan";
import PaymentHistory from "../components/pages/PaymentHistory";
import ScheduleStatus from "../components/pages/ScheduleStatus";
import AuditLog from "../components/pages/AuditLog";
import UserActivity from "../components/pages/UserActivity";
import Profile from "../components/pages/Profile";
import CommByProduct from "../components/pages/CommByProduct";
import CommByCustomer from "../components/pages/CommByCustomer";
import GoogleAnalytics from "../GoogleAnalytics";

export const resourceComponents = {
  [ASSIGN_COMMISSION_SCREEN]: AssignCommission,
  [BENEFICIARY_SCREEN]: Beneficiary,
  [FIELD_MAPPING_SCREEN]: FieldMapping,
  [COMMISSION_SCREEN]: Commission,
  [COMPANY_SETUP_SCREEN]: CompanySetup,
  Dashboard,
  [CUSTOMER_SCREEN]: Customer,
  [PAY_FREQUENCY_SCREEN]: PayFrequency,
  [PRODUCT_SCREEN]: Product,
  [REPORT_SCREEN]: Report,
  [REPORT_R_SCREEN]: ReportR,
  [COMMISSION_BY_PRODUCT_SCREEN]: CommByProduct,
  [COMMISSION_BY_CUSTOMER_SCREEN]: CommByCustomer,
  [ROLE_SCREEN]: Role,
  [SCHEDULE_SCREEN]: Schedule,
  [SCHEDULE_STATUS_SCREEN]: ScheduleStatus,
  [TRANSACTION_SCREEN]: Transaction,
  [VARIABLE_SCREEN]: Variables,
  [TYPE_SCREEN]: Type,
  [TICKET_SCREEN]: Ticket,
};

function AllRouter() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize isAuthenticated from sessionStorage
    return sessionStorage.getItem("isAuthenticated") === "true";
  });
  const [res, setRes] = useState(() => {
    // Initialize response from sessionStorage
    return sessionStorage.getItem("response") === "JWT token validated.";
  });

  const [userRoleAccess, setUserRoleAccess] = useState(() => {
    const roleAccess = sessionStorage.getItem("UserRoleAccess");
    // Parse the string back to an array of objects or provide an empty array as a default
    // return JSON.parse(roleAccess) || [];
    return roleAccess;
  });

  useEffect(() => {
    // Update sessionStorage when isAuthenticated changes
    sessionStorage.setItem("isAuthenticated", isAuthenticated);
    // Convert userRoleAccess to JSON string before storing it in sessionStorage
    sessionStorage.setItem("UserRoleAccess", userRoleAccess);
    sessionStorage.setItem("res", res);
  }, [isAuthenticated, res, userRoleAccess]);

  let arrayOfObjects = Array.isArray(JSON.parse(userRoleAccess))
    ? JSON.parse(userRoleAccess)
    : [];

  const role = USER_ROLE();
  const isMonitor = IS_MONITOR();
  const isSuperAdmin = IS_SUPER_ADMIN();

  // Merge initialResourceComponents with role-dependent Dashboard
  const resource = {
    ...resourceComponents,
    Dashboard: role === ADMIN_NAME ? Dashboard : IndividualDashboard,
  };

  // Private Routes
  function componentMapping(arrayOfObjects) {
    try {
      const mapping = {};

      // Ensure arrayOfObjects is an array
      if (!Array.isArray(arrayOfObjects)) {
        console.error("arrayOfObjects is not an array or is undefined");
        return mapping;
      }

      Object.entries(resource).forEach(([resource, Component]) => {
        mapping[resource] = arrayOfObjects.find(
          (obj) => obj.resource === resource && obj.readAccess
        )
          ? Component
          : null;
      });
      return mapping;
    } catch (error) {
      console.error("Error in componentMapping:", error);
      return {};
    }
  }

  const generateRoutes = () => {
    if (arrayOfObjects.length === 0) {
      arrayOfObjects = [
        {
          resource: DASHBOARD_SCREEN,
          menuManagement: true,
          readAccess: true,
          writeAccess: true,
          deleteAccess: true,
        },
      ];
    }

    const mappedComponents = componentMapping(arrayOfObjects);

    return (
      Array.isArray(arrayOfObjects) &&
      arrayOfObjects.map((route, index) => {
        const { resource, menuManagement } = route;
        const routePath = resource.toLowerCase();
        const Component = mappedComponents[resource]; // Retrieve the component from mappedComponents

        // Check if the component exists and menuManagement is true
        if (Component && menuManagement) {
          return (
            <Route
              key={index}
              index
              path={routePath}
              element={createElement(Component)}
            />
          );
        } else {
          return null; // Return null for routes with missing components or menuManagement is false
        }
      })
    );
  };

  const navigate = useNavigate();

  // Session Data
  const email = EMAIL_ID();
  const session_id = SESSION_ID();
  const ip_address = IP_ADDRESS();

  let inactivityTimer;
  let countdownInterval;
  let toastId;
  // const initialDelay = 10000; // 10 seconds
  // const countdownDuration = 10; // 10 seconds
  const initialDelay = 60000; // 1 min or 60 seconds
  const countdownDuration = 60; // 60 seconds
  // const initialDelay = 1800000; // 30 min
  // const countdownDuration = 60; // 60 seconds
  let remainingTime = countdownDuration;
  let countdownStarted = false;
  let startTime = null;

  const isLoggedIn = !!email && !!session_id; // Check if the user is logged in

  const showToast = () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    toastId = toast(
      `You will be logged out in ${Math.ceil(remainingTime)} seconds`,
      {
        id: toastId,
        duration: Infinity,
      }
    );
  };

  const handleLogoutTimer = () => {
    // Clear any existing timers to avoid overlaps
    clearTimeout(inactivityTimer);

    inactivityTimer = setTimeout(() => {
      try {
        if (!countdownStarted) {
          startTime = performance.now(); // Initialize start time
          remainingTime = countdownDuration;
          countdownStarted = true;
          showToast();

          // Start countdown interval safely
          countdownInterval = setInterval(async () => {
            try {
              await updateCountdown();
            } catch (error) {
              console.error("Error during countdown update:", error);
              clearInterval(countdownInterval); // Stop the interval on error
            }
          }, 1000);
        }
      } catch (error) {
        console.error("Error in inactivity timeout:", error);
      }
    }, initialDelay);
  };

  const updateCountdown = async () => {
    try {
      const currentTime = performance.now();
      const elapsed = (currentTime - startTime) / 1000; // Convert ms to seconds
      remainingTime = countdownDuration - elapsed;

      if (remainingTime <= 0) {
        // Clear the interval when the timer ends
        clearInterval(countdownInterval);

        // Logout logic
        const sessionLogoutValues = {
          email,
          sessionId: session_id,
          ipAddress: ip_address,
        };

        try {
          const logoutRes = await ApiService.logoutSessionUser(
            sessionLogoutValues
          );

          // Check if the current path is in the excluded paths array
          if (excludedPaths.includes(window.location.pathname)) return;

          // Check if logout was successful
          if (logoutRes?.status === 200) {
            toast.dismiss();
            sessionStorage.clear();
            navigate("/session_expired");
            return;
          }
        } catch (error) {
          console.error("Error during logout:", error);
        }
      } else {
        showToast();
      }
    } catch (error) {
      console.error("Error in updateCountdown:", error);
    }
  };

  const resetTimer = () => {
    try {
      clearTimeout(inactivityTimer);
      clearInterval(countdownInterval);
      countdownStarted = false;
      remainingTime = countdownDuration;

      if (toastId) {
        toast.dismiss(toastId);
        toastId = null;
      }
      if (isLoggedIn) handleLogoutTimer(); // Restart the timer only if logged in
    } catch (error) {
      console.error("Error resetting the timer:", error);
    }
  };

  useEffect(() => {
    if (!isLoggedIn || excludedPaths.includes(window.location.pathname)) return;

    const cleanUp = () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(inactivityTimer);
      clearInterval(countdownInterval);
    };

    try {
      events.forEach((event) => window.addEventListener(event, resetTimer));
      handleLogoutTimer();
    } catch (error) {
      console.error("Error in useEffect setup:", error);
      cleanUp();
    }

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, excludedPaths]);

  const propMap = { setIsAuthenticated, setRes, setUserRoleAccess };

  return (
    <>
      <HelmetProvider>
        <GoogleAnalytics />
        <Routes>
          {/* Private Routes */}
          {isAuthenticated && res ? (
            <>
              <Route
                element={
                  <HorizontalLayout
                    setIsAuthenticated={setIsAuthenticated}
                    setRes={setRes}
                    arrayOfObjects={arrayOfObjects}
                    role={role}
                    isMonitor={isMonitor}
                    isSuperAdmin={isSuperAdmin}
                  />
                }
              >
                {/* Dynamically Generated Routes */}
                {generateRoutes()}

                {/* Role-based Routes */}
                {role === ADMIN_NAME && (
                  <>
                    <Route path="/auditLog" element={<AuditLog />} />
                    <Route path="/access" element={<Access />} />
                    <Route path="/billing" element={<Billing />} />
                    <Route path="/pricing" element={<Pricing />} />
                  </>
                )}

                {isMonitor === "true" && (
                  <Route path="/monitor" element={<Monitor />} />
                )}

                {isSuperAdmin === "true" && (
                  <>
                    <Route path="/plan" element={<Plan />} />
                    <Route
                      path="/paymentHistory"
                      element={<PaymentHistory />}
                    />
                  </>
                )}

                {(isMonitor === "true" || isSuperAdmin === "true") && (
                  <>
                    <Route path="/ticket" element={<Ticket />} />
                    <Route path="/userActivity" element={<UserActivity />} />
                  </>
                )}

                {/* Common Route */}
                {isMonitor === "false" && isSuperAdmin === "false" && (
                  <Route path="/profile" element={<Profile />} />
                )}
              </Route>

              {/* Reset Password Route */}
              {isMonitor === "false" && isSuperAdmin === "false" && (
                <Route path="/reset_pwd" element={<ResetPwd />} />
              )}
            </>
          ) : (
            <>
              {/* Redirect for Unauthenticated Users */}
              <Route path="/signin" element={<Navigate to="/sign_in" />} />
              <Route path="/billing" element={<Navigate to="/sign_in" />} />
            </>
          )}

          {/* Public Routes */}
          <Route path="/" element={<Outlet />}>
            {publicRoutesConfig.map(({ path, element, props: keys }) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    {/* The MetaTag component will update the metadata based on the current route */}
                    <MetaTag />

                    {keys
                      ? cloneElement(
                          element,
                          keys.reduce(
                            (acc, key) => ({ ...acc, [key]: propMap[key] }),
                            {}
                          )
                        )
                      : element}
                  </>
                }
              />
            ))}
          </Route>

          <Route path="*" element={<AccessDenied />} />
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default AllRouter;
