// To store the environmental configurations

export const BE_URL = process.env.REACT_APP_BE_URL;

export const API_URL = process.env.REACT_APP_API_URL;

export const API_RECOVER_PWD_URL = process.env.REACT_APP_API_RECOVER_PWD_URL;

export const PWD_IV = process.env.REACT_APP_PWD_IV;

export const PWD_SECRET = process.env.REACT_APP_PWD_SECRET_KEY;

export const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
