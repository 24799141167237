import { useState } from "react";
import { Link } from "react-router-dom";
import { GridMenuIcon } from "@mui/x-data-grid";
import { LoginTwoTone } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { CfTransLogo } from "../../config/imageConfig";

const settings = [
  {
    text: "Free Register",
    link: "/register",
  },
  {
    text: "Sign In",
    link: "/sign_in",
  },
];

export default function TopBarWithDrawer({ isRightBtns = true }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Handle opening and closing of the Drawer
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <>
      {/* Topbar */}
      <AppBar position="sticky" elevation={0} sx={{ p: 1.3 }}>
        <Container>
          <Toolbar>
            <img src={CfTransLogo} alt="Logo" style={{ height: "66px" }} />
            {isRightBtns && (
              <>
                <Box
                  sx={{
                    marginLeft: "auto",
                    "& > *": { marginLeft: 1 },
                    // Show AppBar buttons only on medium and larger screens (md and up)
                    display: { xs: "none", sm: "none", md: "flex" },
                  }}
                >
                  {settings.map((setting, index) => (
                    <Button
                      key={setting.text}
                      component={Link}
                      to={setting.link}
                      size="large"
                      variant={
                        setting.text === "Free Register"
                          ? "outlined"
                          : "contained"
                      }
                      sx={{
                        backgroundImage:
                          setting.text === "Free Register"
                            ? "transparent"
                            : "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                        borderRadius: "20px",
                        ml: 2,
                        height: "40px",
                        borderWidth: "2px",
                        borderColor:
                          setting.text === "Free Register"
                            ? "rgb(59 130 246 / 0.5)"
                            : "white",
                        color:
                          setting.text === "Free Register" ? "black" : "white",
                        backgroundColor:
                          setting.text === "Free Register"
                            ? "white"
                            : "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                        border: setting.text === "Sign In" ? "solid" : null,
                        ":hover": {
                          background:
                            setting.text === "Free Register"
                              ? "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)"
                              : null,
                          color:
                            setting.text === "Free Register" ? "white" : null,
                          border:
                            setting.text === "Free Register" ? "solid" : null,
                        },
                      }}
                    >
                      {setting.text}
                    </Button>
                  ))}
                </Box>

                {/* Menu icon button for small screens */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    marginLeft: "auto",
                  }}
                >
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => toggleDrawer(true)}
                  >
                    <GridMenuIcon />
                  </IconButton>
                </Box>

                {/* Drawer for small screens */}
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={() => toggleDrawer(false)}
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <List>
                    {settings.map((setting, index) => (
                      <ListItemButton
                        component={Link}
                        to={setting.link}
                        key={index}
                        onClick={() => toggleDrawer(false)}
                        sx={{ ":hover": { color: "secondary.main" } }}
                      >
                        {setting.text === "Sign In" && (
                          <LoginTwoTone fontSize="small" />
                        )}
                        <ListItemText>
                          <Typography textAlign="center">
                            {setting.text}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </Drawer>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
