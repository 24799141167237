import { useState } from "react";
import { Box, Popper } from "@mui/material";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";
import { format, isValid, parseISO } from "date-fns";
import { PWD_IV, PWD_SECRET } from "./envConfig";
import {
  BENEFICIARY_SCREEN,
  CUSTOMER_SCREEN,
  PRODUCT_SCREEN,
  TRANSACTION_SCREEN,
} from "./constants";

// Actual email validation pattern
export const actualEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Function to get error message for each case for Password Validation
export const getPasswordErrorMessage = (value) => {
  if (/\s/.test(value)) {
    return "Whitespace is not allowed";
  }
  if (
    !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/.test(
      value
    )
  ) {
    return "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character.";
  }
  if (value.length < 8) {
    return "Password must be at least 8 characters long";
  }
  if (value.length > 32) {
    return "Password cannot exceed 32 characters";
  }
  // Return null if no validation error
  return null;
};

// Business Email Validations

const invalidMails = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "zoho.com",
  "mail.com",
  "protonmail.com",
  "hotmail.com",
];

export const isValidEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const forbiddenDomains = invalidMails;
  if (!emailRegex.test(value)) {
    return "Invalid email address format";
  }
  const domain = value.split("@")[1];
  const lowercasedEnteredValue = domain?.toLowerCase() || "";
  if (forbiddenDomains.includes(lowercasedEnteredValue)) {
    return "Company email address only allowed ";
  }
  return true;
};

// Encode Password
const IV = PWD_IV;
const SECRET_KEY = PWD_SECRET;

export const encryptPwd = (plainText) => {
  try {
    // Truncate or pad the IV to 16 bytes (128 bits)
    const ivBytes = CryptoJS.enc.Utf8.parse(IV.substring(0, 16));
    const encrypted = CryptoJS.AES.encrypt(
      plainText,
      CryptoJS.enc.Utf8.parse(SECRET_KEY),
      {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  } catch (error) {
    return null;
  }
};

export const decryptAes = (encryptedText) => {
  try {
    // Truncate or pad the IV to 16 bytes (128 bits)
    const ivBytes = CryptoJS.enc.Utf8.parse(IV.substring(0, 16));

    // Decrypt the text using AES
    const decrypted = CryptoJS.AES.decrypt(
      encryptedText,
      CryptoJS.enc.Utf8.parse(SECRET_KEY),
      {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    // Convert the decrypted data to a UTF-8 string
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// to allow 50 words only
export const isBeyond50Words = (value) => {
  if (!value) {
    return true; // No error if the value is empty or undefined
  }
  const wordCount = value.trim().split(/\s+/).length;
  return wordCount <= 50 || "Maximum 50 words allowed";
};

// Limit the input to 80 characters
export const is80CharLimit = (value) => {
  if (!value) {
    return true; // No error if the value is empty or undefined
  }
  if (value.length > 80) {
    return "Input cannot exceed 80 characters."; // Return error message if the limit is exceeded
  }
  return true; // Return true if validation passes
};

export const isEmpty = (value) => {
  // Skip validation if the value is not a string
  if (typeof value !== "string" || !value) {
    return true;
  }

  // Check if the value is an empty string or contains only whitespace
  if (value.trim() === "") {
    return "Empty space not allowed";
  }

  // Check if the value has leading or trailing spaces
  if (value !== value.trim()) {
    return "Value cannot have leading or trailing spaces";
  }

  // If the value passes both checks, return true
  return true;
};

export const isValueContainsSplChars = (value) => {
  // Regular expression to match any character that is not a letter, number, space, underscore, or hyphen
  const regex = /[^A-Za-z0-9\s_-]/;

  if (regex.test(value)) {
    return "Special characters are not allowed except - _";
  }

  return true; // Return true if validation passes
};

export const isValueStartsWithNumber = (value, name) => {
  // Regular expression to check if the first character is a digit
  const startsWithNumber = /^\d/;

  // Check if the value starts with a number
  if (startsWithNumber.test(value)) {
    return `${name} should not starts with a number`;
  }

  return true; // Return an true if the input is valid
};

// Call the addSerialNumber function to modify rows
export const addSerialNumber = (rows) => {
  if (!Array.isArray(rows)) {
    console.error("Expected an array");
    return [];
  }
  return rows.map((row, index) => {
    return { ...row, sNo: index + 1 };
  });
};

// Function to check for duplicates
export const isDuplicateWithCaseSensitive = (
  value,
  formData,
  fieldName,
  editItemId,
  fieldLabel
) => {
  if (!Array.isArray(formData)) {
    console.error("Expected formData to be an array");
    return true; // Assume valid if formData is not an array
  }

  const duplicateExists = formData.some(
    (item) => item[fieldName] === value && item.id !== editItemId
  );

  return duplicateExists ? `${fieldLabel} already exists` : true;
};

// Format Duplicate check for the fields

// Function to format the label by capitalizing the first letter and separating camel case words with spaces
function formatLabel(label) {
  return (
    label.charAt(0).toUpperCase() +
    label.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
  );
}

// Function to check if two labels are duplicates considering variations in spacing and capitalization
export function areLabelsDuplicates(label1, label2) {
  // Format both labels
  const formattedLabel1 = formatLabel(label1);
  const formattedLabel2 = formatLabel(label2);

  // Check if the formatted labels are the same
  return formattedLabel1 === formattedLabel2;
}

export const transformToObject = (customFields, mappingValue) => {
  const transformed = {};

  // Add keys from customFields with their values as their keys
  Object.keys(customFields).forEach((key) => {
    transformed[key] = customFields[key][key];
  });

  // Add keys from mappingValue with their original values
  Object.keys(mappingValue).forEach((key) => {
    transformed[key] = mappingValue[key];
  });

  return transformed;
};

// This function is to handle the case when result is an object
export const transformToObjectFromObject = (mappingValue) => {
  const transformed = {};

  // Add keys from mappingValue with their original values
  Object.keys(mappingValue).forEach((key) => {
    transformed[key] = mappingValue[key];
  });

  return transformed;
};

export const transformFields = (fields) => {
  const transformed = {};

  Object.keys(fields).forEach((key) => {
    if (typeof fields[key] === "string") {
      transformed[key] = {
        [key]: fields[key],
        dataType: "text",
        isMandatory: false,
      };
    } else if (typeof fields[key] === "object") {
      transformed[key] = {
        [key]: "",
        dataType: fields[key].dataType || "text",
        isMandatory:
          fields[key].isMandatory !== undefined
            ? fields[key].isMandatory
            : false,
      };
    }
  });

  return transformed;
};

export const fData = (mainFormData) => {
  if (
    mainFormData.customFields &&
    typeof mainFormData.customFields === "object"
  ) {
    const extractedData = Object.keys(mainFormData.customFields).reduce(
      (acc, key) => {
        if (
          mainFormData.customFields[key] &&
          typeof mainFormData.customFields[key] === "object" &&
          mainFormData.customFields[key][key]
        ) {
          acc[key] = mainFormData.customFields[key][key];
        } else {
          console.log(`Invalid structure for key ${key} in customFields.`);
        }
        return acc;
      },
      {}
    );

    return {
      ...mainFormData,
      customFields: extractedData,
    };
  } else {
    return mainFormData; // Return the original data if there's an error
  }
};

// Default custom field for beneficiary screen
export const defaultCustomFields = [
  "salaryAccount",
  "payoutCurrency",
  "adjustmentToPayout",
  "recoveryFromPayout",
  "targetCompensation",
];

// dropdowns for selected screen
export const PAGE_OPTIONS = [
  BENEFICIARY_SCREEN,
  CUSTOMER_SCREEN,
  PRODUCT_SCREEN,
  TRANSACTION_SCREEN,
];

// Adjustment Options For Assign Commission
export const adjustmentOptions = [
  "Cap Adjust",
  "Draw/Adv Adjust",
  "Min Pay Adjust",
  "Other Adjust",
  "Adjust To Payout",
  "Recov From Payout",
];

// Normalize the string by removing whitespace and converting to lowercase
export const normalizeString = (str) => str.replace(/\s+/g, "").toLowerCase();

// To remove the fields from commission screen
export const excludePatterns =
  /^calcDescription_|^calcEntityName_|^calcOption_|^calcSymbols_|^calcField_|^field_|^conDescription_|^symbols_|^conditionEntityName_|^endBrace_|^startBrace_|^conditionOption_|^percentage_/;

// Validation for check duplicates
export const isDuplicate = (
  userIdCheck,
  fieldName,
  enteredValue,
  mode,
  editFormData,
  editFieldName,
  isEData
) => {
  console.log(
    "isDuplicate",
    userIdCheck,
    fieldName,
    enteredValue,
    mode,
    editFormData,
    editFieldName,
    isEData
  );

  if (!Array.isArray(userIdCheck)) {
    console.error("userIdCheck is not an array");
    // return null;
    return true; // Assuming valid input if userIdCheck is not an array
  }

  const trimmedEnteredValue = enteredValue.trim();
  const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();
  if (enteredValue !== trimmedEnteredValue) {
    // Leading or trailing spaces detected
    return `Please remove any leading or trailing spaces from the ${fieldName}.`;
  }

  if (
    mode === "add" &&
    userIdCheck.some(
      (name) =>
        typeof name === "string" &&
        name.toLowerCase() === lowercasedEnteredValue
    )
  ) {
    return `${fieldName} already exists`;
  } else if (
    mode === "edit" &&
    !isEData &&
    userIdCheck.some((name) => name.toLowerCase() === lowercasedEnteredValue) &&
    editFormData?.[editFieldName]?.toLowerCase() !== lowercasedEnteredValue
  ) {
    return `${fieldName} already exists`;
  } else if (
    mode === "edit" &&
    isEData &&
    userIdCheck.some((name) => name.toLowerCase() === lowercasedEnteredValue)
  ) {
    return `${fieldName} already exists`;
  } else {
    return true; // Return true for a valid input
  }
};

// Custom isValidTransaction function
export const isValidTransaction = (
  transactionId,
  transactionLine,
  mode,
  editItemId,
  editFormData,
  transactionCombos,
  isEData
) => {
  console.log(
    "isValidTransaction",
    transactionId,
    transactionLine,
    mode,
    editItemId,
    editFormData,
    transactionCombos,
    isEData
  );

  if (!Array.isArray(transactionCombos)) {
    console.error("transactionCombos is not an array");
    return true; // Assuming valid input if transactionCombos is not an array
  }

  if (transactionId && transactionLine) {
    const isDuplicate = transactionCombos.some(
      (item) =>
        item.transactionId.toLowerCase() === transactionId.toLowerCase() &&
        item.transactionLine.toString() === transactionLine.toString()
    );

    // Ignore validation against editItemId if in 'edit' mode
    if (mode === "edit" && editItemId && editFormData && !isEData) {
      const isCurrentItem =
        editFormData.transactionId.toLowerCase() ===
          transactionId.toLowerCase() &&
        editFormData.transactionLine.toString() === transactionLine.toString();
      if (isCurrentItem) {
        return true; // Allow current item to remain valid
      }
    } else if (mode === "edit" && editItemId && editFormData && isEData) {
      return isDuplicate
        ? "Transaction ID & Transaction Line combination already exist"
        : true;
    }
    console.log(transactionId, transactionLine, isDuplicate);

    return isDuplicate
      ? "Transaction ID & Transaction Line combination already exist"
      : true;
  }

  return true; // If either field is empty, no validation error
};

// Function to transform the HTML string for Commission & Assign Commission Editor format
export const transformHTML = (html) => {
  // Check if html is a valid string
  if (typeof html !== "string") {
    return "";
  }

  // Step 1: Wrap content inside <span> tags with the style attribute
  let transformedHtml = html.replace(
    /<([a-z]+)([^>]*)style="([^"]*?)"([^>]*)>(.*?)<\/\1>/gi,
    (match, tag, beforeAttrs, style, afterAttrs, content) => {
      return `<${tag}${beforeAttrs}${afterAttrs}><span style="${style}">${content}</span></${tag}>`;
    }
  );

  // Step 2: Remove empty <span> tags
  transformedHtml = transformedHtml.replace(/<span[^>]*><\/span>/gi, "");

  // Step 3: Remove nested <span> tags with identical styles
  transformedHtml = transformedHtml.replace(
    /<span[^>]*><span style="([^"]*)">([^<]*)<\/span><\/span>/gi,
    (match, style, content) => {
      return `<span style="${style}">${content}</span>`;
    }
  );

  return transformedHtml;
};

// Prevents input of non-numeric symbols ('e', 'E', '+', '-') in number fields
export const preventNonNumericInput = (event) => {
  if (
    event.key === "e" ||
    event.key === "E" ||
    event.key === "+" ||
    event.key === "-"
  ) {
    event.preventDefault();
  }
};

// Prevents input of non-digit symbols ('e', 'E', '+', '-', '.') in number fields
export const preventNonDigitInput = (event) => {
  if (
    event.key === "e" ||
    event.key === "E" ||
    event.key === "+" ||
    event.key === "-" ||
    event.key === "."
  ) {
    event.preventDefault();
  }
};

//Show the toasting message in fileUpload
export const showToastWithMessage = (
  message,
  isError = false,
  autoCloseTime = 5000
) => {
  let dismissTimeoutRef = null;

  // Countdown logging
  let countdown = autoCloseTime / 1000;
  const interval = setInterval(() => {
    if (countdown > 0) {
      console.log(`Countdown: ${countdown} seconds`);
      countdown--;
    } else {
      clearInterval(interval);
    }
  }, 1000);

  const safeSetItem = (key, value) => {
    try {
      if (typeof sessionStorage !== "undefined") {
        sessionStorage.setItem(key, value);
      }
    } catch (error) {
      console.error(`Error setting sessionStorage key "${key}":`, error);
    }
  };

  const safeGetItem = (key) => {
    try {
      return typeof sessionStorage !== "undefined"
        ? sessionStorage.getItem(key)
        : null;
    } catch (error) {
      console.error(`Error getting sessionStorage key "${key}":`, error);
      return null;
    }
  };

  const toastContent = (
    <div
      onMouseEnter={() => {
        try {
          safeSetItem("isHovering", "true");
          if (dismissTimeoutRef) clearTimeout(dismissTimeoutRef);
        } catch (error) {
          console.error("Error on mouse enter:", error);
        }
      }}
      onMouseLeave={() => {
        try {
          safeSetItem("isHovering", "Not");
          dismissTimeoutRef = setTimeout(() => {
            toast.dismiss();
            safeSetItem("isFileUpload", "false");
          }, 200);
        } catch (error) {
          console.error("Error on mouse leave:", error);
        }
      }}
    >
      <div>{message}</div>
      <div style={{ fontSize: "12px", color: "#6c757d" }}>
        The import button is disabled for a few seconds.
      </div>
    </div>
  );

  safeSetItem("isHovering", "Not");

  try {
    if (isError) {
      toast.error(toastContent, { autoClose: false });
    } else {
      toast.success(toastContent, { autoClose: false });
    }
  } catch (error) {
    console.error("Error showing toast message:", error);
  }

  dismissTimeoutRef = setTimeout(() => {
    try {
      if (safeGetItem("isHovering") === "Not") {
        toast.dismiss();
        safeSetItem("isFileUpload", "false");
      }
    } catch (error) {
      console.error("Error during timeout execution:", error);
    }
  }, autoCloseTime);

  // Cleanup interval if needed
  return () => {
    clearTimeout(dismissTimeoutRef);
    clearInterval(interval);
  };
};

// check specific fields
const filterFields = (item) => {
  const { resource, menuManagement, readAccess, writeAccess, deleteAccess } =
    item;
  return { resource, menuManagement, readAccess, writeAccess, deleteAccess };
};

// compare two array in Access screen
export const arraysAreEqual = (arr1, arr2) => {
  const filteredArr1 = arr1
    .map(filterFields)
    .sort((a, b) => a.resource.localeCompare(b.resource));
  const filteredArr2 = arr2
    .map(filterFields)
    .sort((a, b) => a.resource.localeCompare(b.resource));

  return JSON.stringify(filteredArr1) === JSON.stringify(filteredArr2);
};

// Ass.comm tooltip table
export const CustomTooltip = ({ content, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => setAnchorEl(event.currentTarget);
  const handleMouseLeave = () => setAnchorEl(null);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ display: "inline-block" }}
    >
      {children}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        style={{ zIndex: 1 }}
      >
        <Box
          sx={{
            "& table": {
              width: "100px", // Table width
              borderCollapse: "collapse",
              border: "1px solid #ddd", // Border around the table
            },
            "& th, & td": {
              border: "1px solid #ddd", // Border around cells
              padding: "3px", // Cell padding
              textAlign: "left",
              fontSize: "12px", // Font size
              maxWidth: "100px", // Maximum width of cells
              overflow: "hidden",
            },
            "& th": {
              backgroundColor: "#f4f4f4",
              fontWeight: "bold",
            },
            maxHeight: "auto", // Max height of tooltip
            overflowY: "auto",
            backgroundColor: "white",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          }}
        >
          {content}
        </Box>
      </Popper>
    </div>
  );
};

export const formatDateAndTime = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} T ${hours}:${minutes}`;
  } else {
    return "";
  }
};

// Utility function to format date
export const formatDate = (dateStr) => {
  if (!dateStr) return "-";
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Function to decode HTML entities (commission screen)
export const decodeHTML = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

// check the braces with if condition in submit
export function areBracesInIFCondition(str) {
  let openParenthesesCount = 0;

  for (let char of str) {
    if (char === "(") {
      openParenthesesCount += 1;
    } else if (char === ")") {
      openParenthesesCount -= 1;

      // If count becomes negative, it means there's an unbalanced closing brace
      if (openParenthesesCount < 0) {
        return false;
      }
    }
  }

  // At the end, openParenthesesCount should be 0 if all braces are matched
  return openParenthesesCount === 0;
}

//check the braces without if condition in showing textbox
export function areBracesBalanced(str) {
  const stack = [];
  const braceMap = new Map();

  // Map each brace to its index for reference
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char === "(") {
      stack.push(i); // Push the index of the open brace onto the stack
    } else if (char === ")") {
      if (stack.length === 0) {
        // No matching open brace for this close brace
        braceMap.set(i, char);
      } else {
        stack.pop(); // Found a matching open brace, pop it from the stack
      }
    }
  }

  // Any remaining open braces in the stack are unmatched
  stack.forEach((index) => braceMap.set(index, "("));

  return braceMap;
}

export function highlightUnbalancedBraces(str, braceMap) {
  const highlightedStringArray = str.split("");

  // Wrap each problematic brace in HTML for red coloring
  braceMap.forEach((brace, index) => {
    highlightedStringArray[index] = `<span style="color: red;">${brace}</span>`;
  });

  return highlightedStringArray.join("");
}

export const excludedPaths = [
  "/",
  "/landing",
  "/sign_in",
  "/access_denied",
  "/register",
  "/verify_token",
  "/forgot_pwd",
  "/recover_pwd",
  "/register_success",
  "/signup_success",
  "/payment_success",
  "/payment_failed",
  "/mfa-verify",
  "/terms_conditions",
  "/privacy_policy",
  "/token_verified",
  "/forgot_success",
  "/session_expired",
  "/session_logout",
];

// capitalized the Org and UserName in dashboard
export function capitalizeWords(name) {
  return name.replace(/\b\w/g, (char) => char.toUpperCase());
}

// Handle focus on the first invalid field
export const orderErrorFocus = (fields, errors, fieldRefs) => {
  console.log("Errors:", errors); // Debugging

  setTimeout(() => {
    try {
      for (const field of fields) {
        if (errors[field.name]) {
          if (fieldRefs.current[field.name]) {
            console.log("Focusing field on error:", field.name); // Debugging
            fieldRefs.current[field.name].focus();
            break; // Focus only on the first field with an error
          }
        }
      }
    } catch (error) {
      console.error("Error in orderErrorFocus timeout:", error);
    }
  }, 10); // Delay to ensure focus is set correctly
};

export const convertCamelToTitle = (str) => {
  if (!str) {
    // Return an empty string if the input is null, undefined, or an empty string
    return "";
  }

  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

export const formatDateDDMMYYYY = (date) => {
  try {
    // Check if the date is valid
    if (!date || !isValid(new Date(date))) {
      return ""; // Return an empty string or any fallback value
    }

    // Format the date to "dd-MM-yyyy"
    return format(new Date(date), "dd-MM-yyyy");
  } catch (error) {
    console.error("Error in formatDateDDMMYYYY function:", error);
    return ""; // Return an empty string or fallback in case of error
  }
};

export const formatDateTime = (dateString) => {
  // Check if dateString is null or undefined
  if (!dateString) {
    return ""; // Or return a default value or message as needed
  }

  try {
    // Parse the ISO date string into a Date object
    const date = parseISO(dateString);

    // Format the date to the desired format: DD-MM-YYYY HH:mm:ss
    return format(date, "dd-MM-yyyy HH:mm:ss");
  } catch (error) {
    console.error("Error formatting date:", error);
    return ""; // Or handle the error as needed
  }
};

export function formatDateWithTimePart(isoDateString) {
  // Handle null or undefined input
  if (!isoDateString) {
    console.error("Date string is null or undefined.");
    return ""; // Or return a default value or message as needed
  }

  try {
    // Parse the ISO string into a Date object
    const date = parseISO(isoDateString);

    // Check if the parsed date is valid
    if (!isValid(date)) {
      console.error("Invalid date format:", isoDateString);
      return ""; // Or return a default value or message as needed
    }

    // Format the date part as MM-dd-yyyy
    const formattedDate = format(date, "MM-dd-yyyy");

    // Get the time part from the original ISO string
    const timePart = isoDateString.split("T")[1];

    // If timePart is undefined or missing, log an error
    if (!timePart) {
      console.error("Time part is missing in the date string:", isoDateString);
      return ""; // Or handle missing time part scenario as needed
    }

    // Rebuild the full formatted string
    return `${formattedDate}T${timePart}`;
  } catch (error) {
    // Catch any other errors and log them
    console.error("Error formatting date:", error);
    return ""; // Or handle the error as needed
  }
}

// Idle Logout
export const events = ["load", "mousemove", "mousedown", "scroll", "keypress"];

// Watch startDate and endDate for changes
export const validateEndDate = (value, startDate) => {
  if (value && !startDate)
    return "Start date is required if end date is provided";
  if (startDate && value && new Date(value) < new Date(startDate))
    return "End date must be greater than or equal to start date";
  return true;
};

// Validation function for startDate
export const validateStartDate = (value, endDate) => {
  // console.log(value,endDate);

  if (endDate && !value)
    return "Start date is required if end date is provided";
  if (endDate && value && new Date(value) > new Date(endDate))
    return "Start date must be less than or equal to end date";
  return true;
};

// Utility function to validate arrays
export const validateArray = (array, field) => {
  if (!Array.isArray(array)) {
    throw new Error(` ${field} not found or invalid`);
  }
};

// Regular expression to check the date format YYYY-MM-DD
export const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

// Limit item display
export const displayLimitedItems = (itemsSet) => {
  const itemsArray = Array.from(itemsSet);
  if (itemsArray.length > 2) {
    return `${itemsArray.slice(0, 2).join(", ")}, ...`;
  }
  return itemsArray.join(", ");
};

// Function to remove duplicate fields by name
export function removeDuplicateFields(fields) {
  const uniqueNames = new Set();
  return fields.filter((field) => {
    if (uniqueNames.has(field.name)) {
      return false; // Duplicate found, don't include in result
    } else {
      uniqueNames.add(field.name);
      return true; // Unique, include in result
    }
  });
}
