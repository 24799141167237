import { Box, Container, Grid, Typography } from "@mui/material";
import FooterBar from "../Landing/Footer";
import TopBarWithDrawer from "../Landing2/TopBarWithDrawer";

export default function PolicyPage({ title = "", contentData = [] }) {
  return (
    <>
      <TopBarWithDrawer isRightBtns={false} />

      <Box
        sx={{
          minHeight: "100vh",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="gradient1"
              color="primary"
              fontSize={{ xs: 30, sm: 33, md: 36 }}
              gutterBottom
            >
              {title} of CompFact
            </Typography>
          </Box>

          {/* Content Grid */}
          <Grid container spacing={2}>
            {contentData.map(
              (
                {
                  subBody,
                  heading,
                  paragraph1,
                  paragraph2,
                  paragraph3,
                  paragraph4,
                  paragraph5,
                },
                index
              ) => (
                <Grid item xs={12} key={index}>
                  <Box>
                    {subBody && (
                      <Typography
                        variant="body1"
                        sx={{ textIndent: 30, mb: 2 }}
                      >
                        {subBody}
                      </Typography>
                    )}
                    {heading && (
                      <Typography
                        variant="h6"
                        color="primary"
                        fontWeight={600}
                        gutterBottom
                      >
                        {heading}
                      </Typography>
                    )}
                    {[
                      paragraph1,
                      paragraph2,
                      paragraph3,
                      paragraph4,
                      paragraph5,
                    ]
                      .filter(Boolean) // Remove null/undefined values
                      .map((content, index) => (
                        <Typography key={index} variant="body1" paragraph>
                          {content}
                        </Typography>
                      ))}
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Container>
      </Box>

      {/* Footer Section */}
      <FooterBar />
    </>
  );
}
