import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function MetaTags() {
  const location = useLocation(); // Get the current route path

  // Metadata configuration
  const metaData = {
    "/sign_in": {
      title: "CompFact | Sign In",
      description: "Sign in to your account",
      keywords: "sign in, login",
    },
    "/forgot_pwd": {
      title: "CompFact | Forgot Password",
      description: "Reset your password",
      keywords: "forgot password, reset",
    },
    "/landing3": {
      title: "Motivate Your Sales Team and Boost Performance",
      description:
        "Elevate sales performance with our automated commission tracking software. Motivate your sales team, track progress, and drive revenue growth with our intuitive platform.",
      keywords:
        "Automated sales commission, Sales performance management, Commission tracking software, Sales team motivation, Revenue growth strategies, Sales analytics",
    },
    "/landing4": {
      title: "Unlock Revenue Growth with Sales Commission Automation",
      description:
        "Maximize revenue growth with our automated sales commission software. Get actionable insights from sales analytics, track performance, and motivate your sales team to succeed.",
      keywords:
        "Sales team incentives, Revenue growth acceleration, Commission management software, Sales performance optimization, Automated commission payments, Sales strategy",
    },
    "/landing5": {
      title: "Automate Incentive Payouts and Reduce Administrative Burdens",
      description:
        "Streamline incentive management with our automated payout solution. Reduce administrative tasks and focus on driving business growth.",
      keywords:
        "Automate incentive payouts, Reduce administrative burdens, Incentive management software, Automated payout solution, Sales incentive automation",
    },
  };

  const defaultMeta = {
    title: "Sales Commission Software | Automate Sales Compensation Management",
    description:
      "Streamline sales commission management with our intuitive software. Automate calculations, track performance, and incentivize your sales team to drive revenue growth.",
    keywords:
      "Sales commission management, Sales commission software, Automated commission calculations, Sales performance tracking, Sales team incentives, Revenue growth",
  };

  const currentMeta = metaData[location.pathname] || defaultMeta;

  return (
    <Helmet>
      <title>{currentMeta.title}</title>
      <meta name="description" content={currentMeta.description} />
      <meta name="keywords" content={currentMeta.keywords} />
    </Helmet>
  );
}
