import { Box, Typography, Grid } from "@mui/material";
import {
  EmailTwoTone,
  FeedbackTwoTone,
  PhoneTwoTone,
} from "@mui/icons-material";
import { CONTACT_MAIL, SALES_MAIL } from "../../config/constants";
import { ImgSlideWhite } from "../../config/imageConfig";

const contactDetails = [
  {
    title: "We're Here to Help!",
    content: [
      "If you have any questions about our sales commission software, need support, or want to provide feedback, please reach out to us.",
    ],
    icon: <FeedbackTwoTone />,
  },
  {
    title: "Feedback",
    content: [
      "We value your feedback! Please let us know how we can improve our software.",
    ],
    icon: <FeedbackTwoTone />,
  },
  {
    title: "Sales Inquiries",
    content: [SALES_MAIL],
    icon: <EmailTwoTone />,
  },
  // {
  //   title: "General Inquiries",
  //   content: [INFO_MAIL],
  //   icon: <EmailTwoTone />,
  // },
  // {
  //   title: "Address",
  //   content: ["456 Business Rd, Suite 100", "Business City, BC 12345"],
  //   icon: <LocationOnTwoTone />,
  // },
  {
    title: "Support",
    content: [CONTACT_MAIL],
    icon: <PhoneTwoTone />,
  },
];

export default function ContactUs() {
  return (
    <Box
      sx={{
        p: { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 },
        backgroundImage: `url(${ImgSlideWhite})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="gradient1"
        gutterBottom
        sx={{
          fontSize: {
            xs: "1.5rem",
            sm: "1.75rem",
            md: "2rem",
            lg: "2.25rem",
            xl: "2.5rem",
          },
          display: "block",
          textAlign: "center",
          pb: 2,
        }}
      >
        Contact Us
      </Typography>

      <Grid container spacing={4}>
        {contactDetails.map(({ title, content, icon }, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1rem", sm: "1.125rem", md: "1.25rem" },
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
              }}
              color={"primary"}
              gutterBottom
            >
              {icon}
              <span style={{ marginLeft: "8px" }}>{title}</span>
            </Typography>
            {content.map((line, idx) => (
              <Typography
                key={idx}
                variant="body1"
                sx={{
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  ml: 4,
                }}
              >
                {line}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
