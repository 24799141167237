import { useRef } from "react";
import {
  ImgEmpower,
  ImgFeature,
  ImgSlideWhite,
  ImgWhyScom,
} from "../../config/imageConfig";
import AboutUs from "../Landing/AboutUs";
import ContactUs from "../Landing/ContactUs";
import FooterBar from "../Landing/Footer";
import FooterTop from "../Landing/FooterTop";
import Section2 from "../Landing/section2";
import KeyFeatures from "../Landing/keyFeatures";
import Section4 from "../Landing/section4";
import Section5 from "../Landing/section5";
import Section6 from "../Landing/section6";
import TopBarWithDrawer from "./TopBarWithDrawer";

export default function Layout({ children }) {
  const pricingRef = useRef(null);

  return (
    <>
      <TopBarWithDrawer />
      {children}

      {/* Second Section */}
      <Section2 bgImgEmpower={ImgEmpower} />

      {/* Third Section */}
      <KeyFeatures bgImgSlide={ImgSlideWhite} bgImgFeature={ImgFeature} />

      {/* Fourth Section */}
      <Section4 bgImgWhyCf={ImgWhyScom} />

      {/* Fifth Section */}
      <Section5 bgImgSlide={ImgSlideWhite} />

      {/* Sixth Section */}
      <Section6 pricingRef={pricingRef} bgImgSlide={ImgSlideWhite} />

      {/* About Us Section */}
      <AboutUs />

      {/* Contact Us Section */}
      <ContactUs />

      {/* Footer Top Section */}
      <FooterTop />

      {/* Footer Section */}
      <FooterBar />
    </>
  );
}
