import {
  ImgSlideTrans,
  ImgSlideWhite,
  Landing2,
  Landing3,
  Landing4,
  Landing5,
} from "../../config/imageConfig";
import {
  L2_S1_Heading,
  L3_S1_Heading,
  L4_S1_Heading,
  L5_S1_Heading,
  Try_Now,
} from "./SectionContent";
import HeroSection from "./HeroSection";
import Layout from "./Layout";

export function LandingScreen2() {
  return (
    <>
      <Layout>
        {/* Hero Section One*/}
        <HeroSection
          heading={L2_S1_Heading}
          coverImage={ImgSlideTrans}
          asideImage={Landing2}
          buttonText={Try_Now}
          isButton={true}
          imgRight={true}
        />
      </Layout>
    </>
  );
}

export function LandingScreen3() {
  return (
    <>
      <Layout>
        {/* Hero Section One*/}
        <HeroSection
          heading={L3_S1_Heading}
          coverImage={ImgSlideWhite}
          asideImage={Landing3}
          buttonText={Try_Now}
        />
      </Layout>
    </>
  );
}

export function LandingScreen4() {
  return (
    <>
      <Layout>
        {/* Hero Section One*/}
        <HeroSection
          heading={L4_S1_Heading}
          coverImage={ImgSlideTrans}
          asideImage={Landing4}
          buttonText={Try_Now}
          isButton={true}
          imgRight={true}
        />
      </Layout>
    </>
  );
}

export function LandingScreen5() {
  return (
    <>
      <Layout>
        {/* Hero Section One*/}
        <HeroSection
          heading={L5_S1_Heading}
          coverImage={ImgSlideWhite}
          asideImage={Landing5}
          buttonText={Try_Now}
        />
      </Layout>
    </>
  );
}
