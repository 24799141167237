import { Box, keyframes, Typography } from "@mui/material";
import { ImgSlideTrans } from "../../config/imageConfig";
import {
  Assessment,
  Calculate,
  CloudUpload,
  Settings,
} from "@mui/icons-material";

const companyHighlights = [
  {
    description:
      "Automated commission calculations and incentive compensation plans",
    icon: <Calculate />,
  },
  {
    description: "Easy import of data from any source",
    icon: <CloudUpload />,
  },
  {
    description: "Customizable plans for fully automated calculations",
    icon: <Settings />,
  },
  {
    description:
      "Seamless integration with our web reporting platform for sales performance insights",
    icon: <Assessment />,
  },
];

// Keyframes for continuous marquee animation
const marqueeKeyframes = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export default function AboutUs() {
  return (
    <Box
      sx={{
        p: { xs: 2, sm: 3, md: 4 },
        backgroundImage: `url(${ImgSlideTrans})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      {/* Hero Section */}
      <Box sx={{ textAlign: "center", mb: { xs: 3, sm: 5 } }}>
        <Typography
          variant="gradient1"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.5rem",
              sm: "1.75rem",
              md: "2rem",
              lg: "2.25rem",
              xl: "2.5rem",
            },
          }}
        >
          About Us
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1rem", sm: "1.125rem" },
            lineHeight: 1.6,
            maxWidth: "900px",
            mx: "auto",
            mt: 1,
          }}
        >
          Compfact is a rapidly growing provider of automated commission
          tracking software based in the USA. Founded by experienced techies and
          compensation administrators, our company addresses the inefficiencies
          of spreadsheet-based commission management, offering a highly
          automated and efficient solution.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1rem", sm: "1.125rem" },
            lineHeight: 1.6,
            maxWidth: "900px",
            mx: "auto",
            mt: 4,
          }}
        >
          Streamline your sales commission management with Compfact's web-based
          software solution. Our innovative Ruke Based Engine technology
          automates complex calculations and data relationships, eliminating
          manual errors and increasing efficiency. Benefits include:
        </Typography>
      </Box>

      {/* Company Highlights Section */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            animation: `${marqueeKeyframes} 20s linear infinite`,
            animationIterationCount: "infinite",
            "&:hover": {
              animationPlayState: "paused",
            },
          }}
        >
          {companyHighlights
            .concat(companyHighlights)
            .map((highlight, index) => (
              <Box
                key={index}
                sx={{
                  minWidth: "300px",
                  textAlign: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  padding: 3,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Box sx={{ mb: 1, color: "primary.main" }}>
                  {highlight.icon}
                </Box>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {highlight.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  {highlight.description}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
