import { Box, Button, Grid, Link, Typography } from "@mui/material";

export default function Section1({ bgImgSlideTop, bgImgSalesBanner }) {
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${bgImgSlideTop})`,
        backgroundSize: { xs: "cover", md: "120%" },
        backgroundPosition: { xs: "top center", md: "center" },
        backgroundRepeat: "no-repeat",
        height: { xs: "auto", md: "100%" },
        minHeight: { xs: "100vh", md: "100vh" },
        width: "100%",
        bgcolor: "white",
      }}
    >
      {/* Content on the left */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            pt: { xs: 5, md: 10 }, // Responsive padding top
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" }, // Center align for small screens, left align for medium and up
            textAlign: { xs: "center", md: "left" }, // Text alignment
            height: "100%",
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontWeight: "bold",
              backgroundImage:
                "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize: { xs: "24px", md: "45px" }, // Responsive font size
              lineHeight: 1.2,
              px: { xs: 3, md: 12 }, // Responsive padding x
            }}
          >
            Welcome to CompFact, Your Complete Sales Commission Solution
          </Typography>

          <Box
            sx={{
              backgroundImage: `url(${bgImgSalesBanner})`,
              backgroundSize: { xs: "cover", md: "contain" }, // Responsive background size
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: { xs: "50vh", md: "100vh" }, // Height in viewport height units
              minHeight: { xs: "50vh", md: "100vh" }, // Minimum height for small screens
              width: "100%",
              display: { xs: "flex", md: "none" }, // Show on small screens only
              justifyContent: "center",
              alignItems: "center", // Center image vertically
              my: { xs: "2vh", md: 0 }, // Vertical margin in viewport height units
            }}
          />

          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "#333",
              fontSize: { xs: "16px", md: "21px" }, // Responsive font size
              px: { xs: 3, md: 12 }, // Responsive padding x
              py: { xs: 1, md: 2 }, // Responsive padding y
            }}
          >
            Experience seamless commission management and empowered sales
            performance with our innovative platform.
          </Typography>

          <Grid
            container
            justifyContent={{ xs: "center", md: "flex-start" }} // Responsive alignment
          >
            <Grid item sx={{ pl: { xs: 3, md: 12 }, pb: { xs: 2, md: 5 } }}>
              <Button
                component={Link}
                href={"/register"} // Use href for navigation in Material-UI Button
                variant="contained"
                size="large"
                sx={{
                  color: "white",
                  backgroundImage:
                    "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                  borderRadius: 10,
                  padding: { xs: "8px 16px", md: "12px 24px" }, // Responsive padding
                }}
              >
                Register Now
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Image on the right */}
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        display={{ xs: "none", sm: "none", md: "flex" }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bgImgSalesBanner})`,
            backgroundSize: { xs: "cover", md: "contain" }, // Responsive background size
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: { xs: "300px", md: "100%" }, // Responsive height
            minHeight: { xs: "300px", md: "100vh" }, // Minimum height for small screens
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Center image vertically
            mt: { xs: 2, md: 0 }, // Responsive margin top
          }}
        />
      </Grid>
    </Grid>
  );
}
