import { CONTACT_MAIL } from "../../config/constants";

export const termsConditionsContent = [
  {
    subBody:
      "These Terms and Conditions govern your use of the Sales Commission Software provided by CompFact, a India corporation us. By accessing or using the Software, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Software.",
  },
  {
    heading: "License",
    paragraph1:
      "Subject to these Terms, we grant you a non-exclusive, revocable license to use the Software for your internal business purposes. You may not sublicense, sell, rent, lease, distribute, or otherwise transfer the Software or any part thereof.",
  },
  {
    heading: "Access and Security",
    paragraph1:
      "You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account or password. You must promptly notify us of any unauthorized use of your account or any other breach of security.",
  },
  {
    heading: "Fees and Payments",
    paragraph1:
      "You agree to pay all fees associated with the use of the Software as set forth in the pricing plan provided by us. All fees are non-refundable.",
  },
  {
    heading: "Data Privacy",
    paragraph1:
      "We will collect and process personal data in accordance with our Privacy Policy, which is incorporated into these Terms by reference. You agree to comply with all applicable data protection laws in connection with your use of the Software.",
  },
  {
    heading: "Intellectual Property",
    paragraph1:
      "All rights, title, and interest in and to the Software, including all intellectual property rights therein, are owned by us or our licensors. You agree not to remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Software.",
  },
  {
    heading: "Limitation of Liability",
    paragraph1:
      "To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, arising out of your access to or use of the Software.",
  },
  {
    heading: "Indemnification",
    paragraph1:
      "You agree to indemnify and hold us harmless from and against any and all claims, damages, liabilities, costs, and expenses, including attorneys' fees, arising out of or in connection with your use of the Software or your breach of these Terms.",
  },
  {
    heading: "Modifications to Terms",
    paragraph1:
      "We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on our website. Your continued use of the Software after the posting of the revised Terms constitutes your acceptance of the changes.",
  },
  {
    heading: "Termination",
    paragraph1:
      "We may terminate your access to the Software at any time for any reason or no reason, without prior notice.",
  },
  {
    heading: "Governing Law",
    paragraph1:
      "These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflicts of law principles.",
  },
  {
    heading: "Entire Agreement",
    paragraph1:
      "These Terms constitute the entire agreement between you and us regarding your use of the Software and supersede all prior or contemporaneous understandings and agreements, whether written or oral.",
  },
  {
    heading: "Contact Us",
    paragraph1: `If you have any questions about these Terms, please contact us at ${CONTACT_MAIL}`,
  },
];
