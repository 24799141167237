import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function GradientButton({ to, children, ...props }) {
  return (
    <Button
      component={Link}
      to={to}
      variant="contained"
      size="large"
      sx={{
        color: "white",
        backgroundImage: "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
        borderRadius: 10,
        mt: 2,
        padding: { xs: "8px 16px", md: "12px 24px" }, // Responsive padding
        ...props,
      }}
    >
      {children}
    </Button>
  );
}
