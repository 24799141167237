import { lazy, Suspense, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { addMonths, format, addDays, addYears } from "date-fns";
import toast from "react-hot-toast";
import FullScreenLoader from "../../common/LoadingScreen";
import {
  formatDateDDMMYYYY,
  preventNonDigitInput,
} from "../../config/fieldConfig";
import { RazorPayLogo, StripePayLogo } from "../../config/imageConfig";
import { CURRENCY_NAME } from "../../config/sessionStorage";
import { INDIAN_CURRENCY } from "../../config/constants";
import {
  AsyncCurrencyAmount,
  formatAmount,
} from "../../../utils/CurrencyConverter";
import useRazorpay from "./razorPay";

// Lazily load the StripePay component
const StripePay = lazy(() => import("./stripePay"));

const UpgradeDialog = ({
  open = false,
  handleClose = () => {},
  plan = {},
  noOfBeneficiary = 1,
  token = "",
  fetchData = () => {},
  renewalMonth = "",
  planRenewalDate = "",
}) => {
  const theme = useTheme(); // Access the theme object for styling
  console.log("plan", plan); // Log the plan object for debugging

  // Determine if the dialog is for upgrading or renewing a plan
  const isUpgrade = !renewalMonth;

  // Check if renewalMonth exists and find a matching duration
  const matchingDuration = plan?.subscriptionPeriods?.find(
    (period) => period?.duration === renewalMonth
  )?.duration;

  // Calculate the minimum duration from available subscription periods
  const minDuration = plan?.subscriptionPeriods?.reduce(
    (min, period) => Math.min(min, period?.duration || min), // Fallback to min
    plan?.subscriptionPeriods[0]?.duration // Use the first period as starting point
  );

  // Set initial selected subscription period value
  const [selectedValue, setSelectedValue] = useState(
    matchingDuration || minDuration || 1 // Default to 1 if no duration is found
  );

  // State for number of beneficiaries, defaults to 1 if no value is provided
  const [noOfBeneficiaries, setNoOfBeneficiaries] = useState(
    noOfBeneficiary || 1
  );

  const [stripeDialogOpen, setStripeDialogOpen] = useState(false); // State to manage Stripe dialog visibility
  const [paymentData, setPaymentData] = useState({}); // State to hold payment data

  // Function to get the currency name
  const currency = CURRENCY_NAME();

  // Handle changes in subscription period selection
  const handleRadioChange = (event) => setSelectedValue(event.target.value);

  // Update selected value when a paper option is clicked
  const handlePaperClick = (value) => setSelectedValue(value);

  const basePrice = plan.price || 0; // Default to 0 if base amount is undefined

  const today = new Date(); // Get the current date

  const paidDate = format(today, "yyyy-MM-dd"); // Format today's date

  // Function to calculate the renewal date based on selected subscription period
  const calculateRenewalDate = (months) => {
    try {
      // Use appropriate date addition based on plan frequency
      return format(
        plan.frequency === "Year"
          ? addDays(addYears(planRenewalDate || today, months), 1) // Calculate for yearly
          : addDays(addMonths(planRenewalDate || today, months), 1), // Calculate for monthly
        "yyyy-MM-dd"
      );
    } catch (error) {
      console.error("Error calculating renewal date:", error); // Log error if any
      return null; // Return null to indicate failure in calculation
    }
  };

  // Prepare subscription details safely, handle empty or missing plan
  const subscriptionDetails = plan.subscriptionPeriods?.reduce(
    (acc, { duration, discountPercentage, totalAmount }) => {
      if (duration && totalAmount) {
        // Ensure duration and totalAmount exist
        acc[duration] = {
          months: duration,
          price: totalAmount,
          discount: discountPercentage / 100,
        };
      }
      return acc; // Return the accumulated object
    },
    {} // Start with an empty object
  );

  const selectedSubscriptionValues = subscriptionDetails[selectedValue] || {};

  const selectedMonth = selectedSubscriptionValues.months || 0;

  const renewalDate = calculateRenewalDate(selectedMonth);

  // Calculate the total amount based on the number of beneficiaries
  let totalAmount = 0;
  try {
    totalAmount = basePrice * selectedMonth * noOfBeneficiaries;
  } catch (error) {
    console.error("Error calculating total amount:", error);
  }

  const discountAmount = totalAmount * selectedSubscriptionValues.discount || 0;

  // Calculate CGST and SGST values if currency is INR
  const cgstValue = currency === INDIAN_CURRENCY ? totalAmount * 0.09 : 0;
  const sgstValue = currency === INDIAN_CURRENCY ? totalAmount * 0.09 : 0;

  const amountToPay =
    Number(totalAmount) +
    Number(cgstValue) +
    Number(sgstValue) -
    Number(discountAmount);

  const subscriptionEndDate = format(
    plan.frequency === "Year"
      ? addYears(planRenewalDate || today, selectedMonth)
      : addMonths(planRenewalDate || today, selectedMonth),
    "yyyy-MM-dd"
  );

  const subscriptionStartDate = planRenewalDate || paidDate;

  const summaryItems = [
    {
      label: "Renewal Date",
      value: formatDateDDMMYYYY(renewalDate),
      isRenew: true,
    },
    { label: "Total", value: <AsyncCurrencyAmount amount={totalAmount} /> },
    ...(discountAmount > 0
      ? [
          {
            label: "Discount Value",
            value: (
              <>
                &#8208;
                <AsyncCurrencyAmount amount={discountAmount} />
              </>
            ),
          },
        ]
      : []),
    ...(currency === INDIAN_CURRENCY
      ? [
          {
            label: "CGST 9%",
            value: <AsyncCurrencyAmount amount={cgstValue} />,
          },
          {
            label: "SGST 9%",
            value: <AsyncCurrencyAmount amount={sgstValue} />,
          },
        ]
      : []),
    {
      label: "Amount To Pay",
      value: <AsyncCurrencyAmount amount={amountToPay} />,
      isBold: true,
    },
  ];

  const { displayRazorpay, isLoading } = useRazorpay({
    payData: paymentData,
    fetchData,
    handleClose,
    token,
    isUpgrade,
  });

  const handlePaymentMethodClick = async (method) => {
    try {
      // Format relevant amounts
      const [
        formattedAmountToPay,
        formattedTotalAmount,
        formattedDiscountAmount,
        formattedCgst,
        formattedSgst,
      ] = await Promise.all(
        [amountToPay, totalAmount, discountAmount, cgstValue, sgstValue].map(
          (amount) => formatAmount({ amount, symbol: false })
        )
      );

      // Log formatted amounts to ensure they're correctly formatted
      console.log(
        "\nFormatted Total Amount:",
        formattedTotalAmount.toFixed(2),
        "\nFormatted Discount Amount:",
        formattedDiscountAmount.toFixed(2),
        "\nFormatted CGST:",
        formattedCgst.toFixed(2),
        "\nFormatted SGST:",
        formattedSgst.toFixed(2),
        "\nFormatted Amount to Pay:",
        formattedAmountToPay.toFixed(2)
      );

      // Construct the payData object with converted values
      const payData = {
        month: selectedValue,
        pricingPlan: plan.title || "",
        totalAmount: formattedTotalAmount.toFixed(2), // Use the formatted total amount here
        discountAmount: formattedDiscountAmount.toFixed(2), // Use the formatted discount amount here
        amountToPay: formattedAmountToPay.toFixed(2), // Use the formatted amount here
        cgst: formattedCgst.toFixed(2), // Include formatted CGST
        sgst: formattedSgst.toFixed(2), // Include formatted SGST
        beneficiary: noOfBeneficiaries,
        subscriptionStartDate,
        subscriptionEndDate,
        renewalDate,
        paidDate,
        currency,
        discountPercentage: (selectedSubscriptionValues?.discount || 0) * 100, // Convert to percentage
      };

      // Set the payment data for the selected payment method
      setPaymentData(payData);

      // Validate the amount before processing payment
      if (amountToPay <= 0) {
        toast.error("The specified amount to pay is not valid.");
      } else {
        // Process payment based on selected method
        if (method === "stripe") {
          setStripeDialogOpen(true);
        } else if (method === "razorpay") {
          displayRazorpay(payData, fetchData, handleClose, token, isUpgrade);
        }
      }
    } catch (error) {
      console.error("Error formatting amount:", error);
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogTitle>
          {/* Display title based on renewal status */}
          {renewalMonth ? "Renew" : "Upgrade"} your Plan
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {/* Inform user about the action they are taking */}
            {renewalMonth ? "Renew" : "Upgrade"} your selected plan and proceed
            to checkout
          </Typography>

          {/* Display selected plan name */}
          <Box
            sx={{
              my: 1,
              p: 1.5,
              bgcolor: "#eeeeee",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle2">Plan Name</Typography>
            <Typography variant="subtitle2">{plan.title || ""}</Typography>
          </Box>

          {/* Input field for the number of beneficiaries */}
          <Box>
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              label="No. of beneficiaries"
              name="noOfBeneficiaries"
              type="number"
              value={noOfBeneficiaries}
              inputProps={{
                min: 1, // Minimum number of beneficiaries is 1
                step: 1, // Increment by 1
              }}
              onChange={(e) => {
                setNoOfBeneficiaries(e.target.value); // Update state on change
              }}
              onKeyDown={(e) => {
                preventNonDigitInput(e); // Handle key down events
              }}
              onWheel={(e) => {
                e.target.blur(); // Prevent scrolling when using mouse wheel
              }}
            />
          </Box>

          {/* Radio group for selecting subscription period */}
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Select Subscription Period</FormLabel>
            <RadioGroup value={selectedValue} onChange={handleRadioChange}>
              {Object.entries(subscriptionDetails)
                .sort((a, b) => a[1].months - b[1].months) // Sort subscription periods by months
                .map(([key, { months, price, discount }]) => (
                  <Paper
                    key={key}
                    onClick={() => handlePaperClick(key)} // Handle click on period selection
                    sx={{
                      p: 1,
                      my: 1,
                      border: `1px solid ${
                        selectedValue === key
                          ? theme.palette.primary.main
                          : "#ccc"
                      }`, // Highlight selected period
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          value={key}
                          control={<Radio color="primary" />} // Radio button
                          label={
                            <Box display="flex" alignItems="center">
                              {/* Display period and discount info */}
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold", mr: 1 }}
                              >
                                {plan.frequency === "Year"
                                  ? `${months} year${months > 1 ? "s" : ""}`
                                  : `${months} month${months > 1 ? "s" : ""}`}
                              </Typography>
                              {discount > 0 && (
                                <Chip
                                  label={`Save ${(discount * 100).toFixed(0)}%`} // Display discount
                                  color="primary"
                                  size="small"
                                  sx={{ ml: 1 }}
                                />
                              )}
                            </Box>
                          }
                          sx={{
                            m: 0,
                            p: 0,
                            flexShrink: 0, // Prevent shrinking of label
                          }}
                        />
                      </Box>

                      {/* Display price per user based on frequency */}
                      <Chip
                        label={
                          <>
                            <AsyncCurrencyAmount amount={price / months} />{" "}
                            {plan.frequency === "Year"
                              ? "Per User/Year"
                              : "Per User/Month"}
                          </>
                        }
                        color="default"
                        size="small"
                        sx={{ fontWeight: "bold" }}
                      />
                    </Box>
                  </Paper>
                ))}
            </RadioGroup>
          </FormControl>

          {/* Order summary section */}
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="subtitle2" gutterBottom>
              Order Summary
            </Typography>
            {summaryItems.map(({ label, value, isBold, isRenew }, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                mb={1}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: isBold ? "bold" : "normal",
                    color: isRenew ? "green" : "inherit", // Change color if it's a renewal
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: isBold ? "bold" : "normal" }}
                >
                  {value} {/* Display the value */}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Payment method selection */}
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Payment Method
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ borderRadius: "12px" }}
                  onClick={() => handlePaymentMethodClick("stripe")} // Stripe payment method
                >
                  <img
                    src={StripePayLogo}
                    alt="Stripe"
                    style={{ width: "100px", height: "40px" }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ borderRadius: "12px" }}
                  onClick={() => handlePaymentMethodClick("razorpay")} // Razorpay payment method
                >
                  <img
                    src={RazorPayLogo}
                    alt="Razorpay"
                    style={{ width: "100px", height: "40px" }}
                  />
                </Button>
              </Grid>
              <FullScreenLoader loading={isLoading} />
              {/* Loader for loading state */}
            </Grid>
          </Box>
        </DialogContent>

        {/* Dialog actions */}
        <DialogActions sx={{ px: 2 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Suspense fallback for lazy loading StripePay component */}
      <Suspense fallback={<div>Loading...</div>}>
        {stripeDialogOpen && (
          <StripePay
            open={stripeDialogOpen}
            onClose={() => setStripeDialogOpen(false)} // Close Stripe dialog
            handleClose={handleClose} // Close main dialog
            payData={paymentData}
            fetchData={fetchData}
            jwtToken={token}
            isUpgrade={isUpgrade}
          />
        )}
      </Suspense>
    </>
  );
};

export default UpgradeDialog;
