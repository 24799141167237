import { styled } from "@mui/material";

// Table columns inside hover table border style
export const tableHoverStyle = {
  maxWidth: {
    xs: "90vw",
    sm: "70vw",
    md: "50vw",
    lg: "40vw",
  },
  maxHeight: "200px",
  overflowY: "auto",
  "& table": {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid black",
  },
  "& th, & td": {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    maxWidth: "200px",
  },
  "& th": {
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
  },
  "& thead th, & tbody td": {
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
  },
  "& tbody tr:last-child td": {
    borderBottom: "1px solid black",
  },
  "& tbody td:last-child, & thead th:last-child": {
    borderRight: "1px solid black",
  },
};

// Styled input for visually hidden file upload
export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// Card style
export const cardStyles = {
  p: 2,
  borderRadius: 4,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    transform: "scale(1.03)",
  },
};
